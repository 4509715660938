import React from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";

const GroupManagement = () => {
    return (
        <div className="group-management-videos">
            <h2>Group Management Tutorials</h2>
            <p>Watch the videos below to learn how to manage groups, register as a group reporter with a group code, and access group reporter feedback in the dashboard.</p>

            <Accordion multiple>
                {/* Video 1: Creating Group Types, Groups, and CSO */}
                <AccordionTab header="How to create group types, groups, and assign a CSO">
                    <video controls style={{ width: "100%" }}>
                        <source src="/user_manual/groups/creating_a_group_and_cso.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </AccordionTab>

                {/* Video 2: Register as a Group Reporter */}
                <AccordionTab header="How to register as a group reporter using the CF app with a group code">
                    <video controls style={{ width: "100%" }}>
                        <source src="/user_manual/groups/cfp_app_register_as_group_reporter.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </AccordionTab>

                {/* Video 3: CSO Access Feedback in CFP Dashboard */}
                <AccordionTab header="How CSOs can access feedback from group reporters in the CFP dashboard">
                    <video controls style={{ width: "100%" }}>
                        <source src="/user_manual/groups/cso_manage_groups.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default GroupManagement;
