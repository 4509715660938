import React from "react";

// material-ui
import Typography from "@mui/material/Typography";

// project import

import ListRecords from "./ListRecords";

function PermissionsPage() {
    return <ListRecords />;
}

export default PermissionsPage;
