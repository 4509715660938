import React from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";

const Notifications = () => {
    return (
        <div className="notifications">
            <h2>Notifications </h2>
            <p>This section provides an overview of how to create notifications within the OAG dashboard. Notifications keep reporters updated on important events. Watch the video below to learn how to create notifications.</p>

            <Accordion multiple>
                {/* Video  for Notifications */}
                <AccordionTab header="How to create a notification">
                    <video controls style={{ width: "100%" }}>
                        <source src="/user_manual/notifications/creating_a_notification.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default Notifications;
