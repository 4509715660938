import axiosAPI from "../axiosApi";

export async function getAllSummarisedFeedbackComments(params = {}) {
    const response = await axiosAPI.get("summarised-feedback-comments", { params: params });
    return response;
}

export async function getSummarisedFeedbackCommentById(id) {
    const response = await axiosAPI.get(`summarised-feedback-comments/` + id);
    return response;
}

export async function postSummarisedFeedbackComment(data) {
    const response = await axiosAPI.post(`summarised-feedback-comments`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateSummarisedFeedbackComment(id, data) {
    const response = await axiosAPI.put(`summarised-feedback-comments/${id}`, data);
    return response;
}

export async function deleteSummarisedFeedbackCommentById(id) {
    const response = await axiosAPI.delete(`summarised-feedback-comments/${id}`);
    return response;
}
