import axiosAPI from "../axiosApi";

export async function getAllDirectorateOffices(params = {}) {
    const response = await axiosAPI.get("directorate-offices", { params: params });
    return response;
}

export async function getDirectorateOfficeById(id) {
    const response = await axiosAPI.get(`directorate-offices/` + id);
    return response;
}

export async function postDirectorateOffice(data) {
    const response = await axiosAPI.post(`directorate-offices`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateDirectorateOffice(id, data) {
    const response = await axiosAPI.post(`directorate-offices/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteDirectorateOfficeById(id) {
    const response = await axiosAPI.delete(`directorate-offices/${id}`);
    return response;
}
