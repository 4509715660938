import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";

//
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

//
import { getAllGroupTypes, deleteGroupTypeById } from "../../../services/grouptypes/grouptypes-service";

import { getAllGroups, deleteGroupById } from "../../../services/groups/groups-service";

import useHandleQueryError from "../../../hooks/useHandleQueryError";

//
import useAuthContext from "../../../context/AuthContext";

const AdvancedFilterForm = ({ onSubmit, initialData, selectedStatuses = [] }) => {
    const { getUserQuery } = useAuthContext();
    const loggedInUserData = getUserQuery?.data?.data;

    const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);

    // ============= Auto Complete States =================
    const [dropdownGroupTypes, setDropdownGroupTypes] = useState([]);
    const [selectedGroupTypes, setSelectedGroupTypes] = useState(loggedInUserData?.role === "CSO Admin" ? loggedInUserData?.group_types ?? [] : initialData?.groupTypes ?? []);

    const [dropdownGroups, setDropdownGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState(loggedInUserData?.role === "CSO Admin" ? loggedInUserData?.groups ?? [] : initialData?.groups ?? []);
    // Fetch group types
    // Group Types Query
    const groupTypesQuery = useQuery({
        queryKey: ["groupTypes", loggedInUserData?.role],
        queryFn: () => getAllGroupTypes({ get_by_logged_in_user: ["CSO Admin"].includes(loggedInUserData?.role) ? true : false }),
    });
    useHandleQueryError(groupTypesQuery.isError, groupTypesQuery.error);

    // Groups Query (depends on selected group type)
    const groupsQuery = useQuery({
        queryKey: ["groups", selectedGroupTypes, loggedInUserData?.role],
        queryFn: () => getAllGroups({ get_by_logged_in_user: ["CSO Admin"].includes(loggedInUserData?.role) ? true : false, groupTypes: selectedGroupTypes }),
        enabled: selectedGroupTypes.length > 0, // Enabled only if group types are selected
    });
    useHandleQueryError(groupsQuery.isError, groupsQuery.error);

    const statuses = [
        { id: 1, name: "Submitted", code: "submitted" },
        { id: 2, name: "auditable", code: "auditable" },
        { id: 3, name: "tagged", code: "tagged" },
        { id: 4, name: "non audit", code: "non audit" },
    ];

    const validate = (values) => {
        const errors = {};
        if (values.startDate && values.endDate && moment(values.startDate).isAfter(values.endDate)) {
            errors.startDate = "Start date cannot be after end date";
        }
        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            onSubmit(data);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please correct the errors before submitting");
        }
    };

    const initialValues = {
        // startDate: initialData?.startDate ?? moment().startOf("month").toDate(),
        startDate: initialData?.startDate ?? moment().subtract(30, "days").toDate(),
        endDate: initialData?.endDate ?? moment().toDate(),
        selectedStatuses: initialData?.selectedStatuses ?? selectedStatuses,
        groupTypes: loggedInUserData?.role === "CSO Admin" ? loggedInUserData?.group_types ?? [] : initialData?.groupTypes ?? [],
        groups: loggedInUserData?.role === "CSO Admin" ? loggedInUserData?.groups ?? [] : initialData?.groups ?? [],
    };

    return (
        <div className="card p-fluid">
            <Accordion activeIndex={accordionActiveIndex} onTabChange={(e) => setAccordionActiveIndex(e.index)}>
                <AccordionTab header="Filters">
                    <Form
                        onSubmit={onSubmitForm}
                        initialValues={initialValues}
                        initialValuesEqual={() => true}
                        // initialValuesEqual with a function returning true prevents the form from
                        // reinitializing when the initialValues prop changes. This is useful when you
                        // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                        // Be cautious using this if your initial values are meant to be dynamic and responsive
                        // to changes in your application's state.
                        validate={validate}
                        render={({ handleSubmit, form, values }) => (
                            <form onSubmit={handleSubmit} className="p-grid p-fluid">
                                <div className="grid">
                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="startDate">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="startDate">Start Date</label>
                                                    <Calendar id="startDate" {...input} dateFormat="dd/mm/yy" showIcon className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="endDate">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="endDate">End Date</label>
                                                    <Calendar id="endDate" {...input} dateFormat="dd/mm/yy" showIcon className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="selectedStatuses">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="status">Filter by Status</label>
                                                    <MultiSelect id="status" {...input} options={statuses} optionLabel="name" placeholder="Select Statuses" className={classNames("w-full", { "p-invalid": meta.error && meta.touched })} display="chip" />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="groupTypes">
                                            {({ input, meta }) => {
                                                const groupTypesData = groupTypesQuery?.data?.data?.data || [];

                                                //=some
                                                // true if the callback function returns a truthy value for at least one element.
                                                // false if the callback function returns false for all elements.

                                                // Filtering for the AutoComplete
                                                const fetchGroupTypes = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = groupTypesData.filter((type) => type?.name?.toLowerCase().includes(query));
                                                    filtered = filtered.filter((groupType) => !selectedGroupTypes.some((selected) => selected.id === groupType.id)); // Exclude selected groupTypes
                                                    setDropdownGroupTypes(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="groupTypes">Group Type</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={true}
                                                            suggestions={dropdownGroupTypes}
                                                            completeMethod={fetchGroupTypes}
                                                            field="name"
                                                            value={selectedGroupTypes}
                                                            onChange={(e) => {
                                                                setSelectedGroupTypes(e.value);
                                                                input.onChange(e.value);

                                                                // Reset groups when group type changes
                                                                setDropdownGroups([]);
                                                                setSelectedGroups([]);

                                                                form.change("groups", null);
                                                            }}
                                                            dropdown={true}
                                                            placeholder="Select Group Type"
                                                            className={meta.touched && meta.error ? "p-invalid" : ""}
                                                            disabled={groupTypesQuery.isLoading}
                                                        />

                                                        {/* Error message */}
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                        {/* Loading spinner */}
                                                        {groupTypesQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        {/* Groups dropdown (depends on group type) */}

                                        <Field name="groups">
                                            {({ input, meta }) => {
                                                const groupsData = groupsQuery?.data?.data?.data || [];

                                                // Filtering for the AutoComplete
                                                const fetchGroups = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = groupsData.filter((group) => group?.name?.toLowerCase().includes(query));
                                                    filtered = filtered.filter((group) => !selectedGroups.some((selected) => selected.id === group.id)); // Exclude selected groups
                                                    setDropdownGroups(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="groups">Groups</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={true}
                                                            suggestions={dropdownGroups}
                                                            completeMethod={fetchGroups}
                                                            field="name"
                                                            value={selectedGroups}
                                                            onChange={(e) => {
                                                                setSelectedGroups(e.value);
                                                                input.onChange(e.value);
                                                            }}
                                                            dropdown={true}
                                                            placeholder="Select Groups"
                                                            className={meta.touched && meta.error ? "p-invalid" : ""}
                                                            disabled={groupsQuery.isLoading || selectedGroupTypes.length === 0}
                                                        />

                                                        {/* Error message */}
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                                        {/* Loading spinner */}
                                                        {groupsQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <div className="p-field m-4">
                                            <div style={{ height: "1rem" }}></div>
                                            <Button type="submit" onClick={() => setAccordionActiveIndex(null)} label="Apply Filters" className="p-button-primary" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default AdvancedFilterForm;
