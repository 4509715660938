import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import Tooltip from "@mui/material/Tooltip";
import FeedbackDetailsModal from "../../../global_views/FeedbackDetailsModal";

const TaggedFeedbacksTable = ({ feedbacks = [], setFeedbacks = () => {} }) => {
    console.log("🚀 ~ TaggedFeedbacksTable ~ feedbacks:", feedbacks);
    // const [feedbacks, setFeedbacks] = useState([
    //     {
    //         id: 12,
    //         feedback_no: "FB-SRH1MSSS",
    //         message: "Poor roads",
    //         created_at: "2024-09-20T12:16:46.000000Z",
    //         county: { name: "Agago west county" },
    //         district: { name: "Agago" },
    //         region: { name: "ACHOLI" },
    //         sector: { name: "Government" },
    //         created_by: { name: "John Doe" },
    //     },
    //     // Add more sample data here if needed
    // ]);

    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const confirmDelete = (rowData) => {
        setSelectedFeedback(rowData);
        setDeleteDialogVisible(true);
    };

    const deleteFeedback = () => {
        setFeedbacks(feedbacks.filter((f) => f.id !== selectedFeedback.id));
        setDeleteDialogVisible(false);
        // You would typically make an API call here to delete the feedback
    };

    const viewFeedback = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
        setSelectedFeedback(null);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-info p-button-text mr-2"
                    onClick={(e) => {
                        e.preventDefault();
                        viewFeedback(rowData);
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(rowData);
                    }}
                />
            </>
        );
    };

    const messageBodyTemplate = (rowData) => {
        const message = rowData?.message;
        const words = message.split(" ");
        const truncatedMessage = words.length > 2 ? words.slice(0, 2).join(" ") + "..." : message;

        return (
            <div style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <Tooltip title={message} arrow placement="top">
                    <span>{truncatedMessage}</span>
                </Tooltip>
            </div>
        );
    };

    return (
        <div>
            <Toast />
            <h6>Tagged Feedback</h6>
            <DataTable value={feedbacks} responsiveLayout="scroll">
                <Column field="feedback_no" header="Feedback No" />
                <Column field="message" header="Message" body={messageBodyTemplate} />
                <Column field="sector.name" header="Sector" />

                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }} />
            </DataTable>

            <ConfirmDialog visible={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} message="Are you sure you want to delete this feedback?" header="Confirmation" icon="pi pi-exclamation-triangle" accept={deleteFeedback} reject={() => setDeleteDialogVisible(false)} />

            {showFeedbackModal && <FeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
        </div>
    );
};

export default TaggedFeedbacksTable;
