import axiosAPI from "../axiosApi";

// export async function getAllFeedbacks(params = {}) {
//     const response = await axiosAPI.get("feedback", { params: params });
//     return response;
// }

export async function postToBulkAssignSummarisedFeedbackToAAGA(data) {
    const response = await axiosAPI.post(`assign-feedback-to-aaga`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkAssignSummarisedFeedbackToDirectorateOfficers(data) {
    const response = await axiosAPI.post(`assign-feedback-to-directorate-officers`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}
