import axiosAPI from "../axiosApi";

// Fetch all feedback statistics
export async function getSummarisedFeedbackBarChartStatistics(params = {}) {
    const response = await axiosAPI.get("summarised-feedback-barchart", { params: params });
    return response;
}

export async function getFeedbackBarChartStatistics(params = {}) {
    const response = await axiosAPI.get("getFeedbackBarChartStats", { params: params });
    return response;
}
