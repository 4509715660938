import React from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";

const DashboardOverview = () => {
    return (
        <div className="dashboard-overview">
            <h2>Dashboard Overview</h2>
            <p>This section provides an overview of how to navigate and use the OAG dashboard effectively. Watch the video below to understand key functionalities, including statistics, visualising feedback on the world map and graphs, configurations etc.</p>

            <Accordion multiple>
                {/* Video Overview */}
                <AccordionTab header="Dashboard Video Overview">
                    <video controls style={{ width: "100%" }}>
                        <source src="/user_manual/dashboard_overview/dashboard_overview.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default DashboardOverview;
