import axiosAPI from "../axiosApi";

export async function getAllServiceFeedbacks(params = {}) {
    const response = await axiosAPI.get("oag-service-feedback", { params: params });
    return response;
}

export async function getServiceFeedbackById(id) {
    const response = await axiosAPI.get(`oag-service-feedback/` + id);
    return response;
}

export async function postServiceFeedback(data) {
    const response = await axiosAPI.post(`oag-service-feedback`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkDeleteServiceFeedback(data) {
    const response = await axiosAPI.post(`services-feedbacks-bulk-delete`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkUpdateServiceFeedbackStatus(data) {
    const response = await axiosAPI.post(`updateServiceFeedbackStatus`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateServiceFeedback(id, data) {
    const response = await axiosAPI.post(`oag-service-feedback/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteServiceFeedbackById(id) {
    const response = await axiosAPI.delete(`oag-service-feedback/${id}`);
    return response;
}
