import axiosAPI from "../axiosApi";

export async function getAllResponses(params = {}) {
    const response = await axiosAPI.get("responses", { params: params });
    return response;
}

export async function getResponseById(id) {
    const response = await axiosAPI.get(`responses/` + id);
    return response;
}

export async function postResponse(data) {
    const response = await axiosAPI.post(`responses`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateResponse(id, data) {
    const response = await axiosAPI.post(`responses/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteResponseById(id) {
    const response = await axiosAPI.delete(`responses/${id}`);
    return response;
}
