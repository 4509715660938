import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllSummarisedFeedbacks, getSummarisedFeedbackById, postSummarisedFeedback, postToBulkDestroySummarisedFeedback, updateSummarisedFeedback, deleteSummarisedFeedbackById } from "../../../services/feedback/summarised-feedback-service";

import RowForm from "./widgets/RowForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function CreateForm({ selectedTableRowsData, setTableSelectedRows, ...props }) {
    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postSummarisedFeedback,
        onSuccess: () => {
            queryClient.invalidateQueries(["feedback"]);
            // queryClient.invalidateQueries(["summarised_feedback"]);
            toast.success("Feedback Summarised Successfully");
            props.onClose();
            setCreactMutationIsLoading(false);
            // setTableSelectedRows([]);
            // selectedTableRowsData([])
        },
    });
    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        console.log("🚀 ~ creating summarised feedback handleSubmit ~ data:", data);

        setCreactMutationIsLoading(true);

        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("sector_id", data?.sector?.id);
        formData.append("message", data?.message);
        formData.append("status", data?.status);
        formData.append("feedback_submit_status", data?.feedback_submit_status ?? "submitted");
        // formData.append("latitude", data?.latitude);
        // formData.append("longitude", data?.longitude);

        // Only append latitude and longitude if they are not null or undefined
        if (data?.latitude !== null && data?.latitude !== undefined) {
            formData.append("latitude", data.latitude);
        }

        if (data?.longitude !== null && data?.longitude !== undefined) {
            formData.append("longitude", data.longitude);
        }

        if (data?.has_attachments !== null && data?.has_attachments !== undefined) {
            formData.append("has_attachments", data?.has_attachments);
        }

        formData.append("location_scope", data?.location_scope);

        switch (data?.location_scope) {
            case "International":
                // if (data?.country?.id !== null && data?.country?.id !== undefined) {
                formData.append("country_id", data?.country?.id ?? "");
                // }

                // if (data?.region?.id !== null && data?.region?.id !== undefined) {
                formData.append("region_id", data?.region?.id ?? "");
                // }
                // if (data?.district?.id !== null && data?.district?.id !== undefined) {
                formData.append("district_id", data?.district?.id ?? "");
                // }
                // if (data?.county?.id !== null && data?.county?.id !== undefined) {
                formData.append("county_id", data?.county?.id ?? "");
                // }
                // if (data?.subcounty?.id !== null && data?.subcounty?.id !== undefined) {
                formData.append("subcounty_id", data?.subcounty?.id ?? "");
                // }
                // if (data?.parish?.id !== null && data?.parish?.id !== undefined) {
                formData.append("parish_id", data?.parish?.id ?? "");
                // }
                // if (data?.village?.id !== null && data?.village?.id !== undefined) {
                formData.append("village_id", data?.village?.id ?? "");
                // }
                break;

            case "National":
                // if (data?.country?.id !== null && data?.country?.id !== undefined) {
                formData.append("country_id", data?.country?.id ?? "");
                // }

                // if (data?.region?.id !== null && data?.region?.id !== undefined) {
                formData.append("region_id", data?.region?.id ?? "");
                // }
                // if (data?.district?.id !== null && data?.district?.id !== undefined) {
                formData.append("district_id", data?.district?.id ?? "");
                // }
                // if (data?.county?.id !== null && data?.county?.id !== undefined) {
                formData.append("county_id", data?.county?.id ?? "");
                // }
                // if (data?.subcounty?.id !== null && data?.subcounty?.id !== undefined) {
                formData.append("subcounty_id", data?.subcounty?.id ?? "");
                // }
                // if (data?.parish?.id !== null && data?.parish?.id !== undefined) {
                formData.append("parish_id", data?.parish?.id ?? "");
                // }
                // if (data?.village?.id !== null && data?.village?.id !== undefined) {
                formData.append("village_id", data?.village?.id ?? "");
                // }
                break;

            case "Local Government":
                // if (data?.country?.id !== null && data?.country?.id !== undefined) {
                formData.append("country_id", data?.country?.id ?? "");
                // }

                // if (data?.region?.id !== null && data?.region?.id !== undefined) {
                formData.append("region_id", data?.region?.id ?? "");
                // }
                // if (data?.district?.id !== null && data?.district?.id !== undefined) {
                formData.append("district_id", data?.district?.id ?? "");
                // }
                // if (data?.county?.id !== null && data?.county?.id !== undefined) {
                formData.append("county_id", data?.county?.id ?? "");
                // }
                // if (data?.subcounty?.id !== null && data?.subcounty?.id !== undefined) {
                formData.append("subcounty_id", data?.subcounty?.id ?? "");
                // }
                // if (data?.parish?.id !== null && data?.parish?.id !== undefined) {
                formData.append("parish_id", data?.parish?.id ?? "");
                // }
                // if (data?.village?.id !== null && data?.village?.id !== undefined) {
                formData.append("village_id", data?.village?.id ?? "");
                // }
                break;

            default:
                // Handle unknown location scope if necessary
                console.warn("Unknown location scope, no location data appended.");
                break;
        }

        // Push the whole selectedTableRowsData array without modification
        formData.append("original_feedback", JSON.stringify(data?.tagged_feedback));

        creactMutation.mutate(formData);
    };

    return (
        <Dialog header="Summarise Feedback Create Form" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <p>Fill in the form below</p>
            <RowForm handleSubmit={handleSubmit} selectedTableRowsData={selectedTableRowsData} initialData={Array.isArray(selectedTableRowsData) && selectedTableRowsData.length > 0 ? selectedTableRowsData[0] : null} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default CreateForm;
