import axiosAPI from "../axiosApi";

export async function getAllCounty(params = {}) {
    const response = await axiosAPI.get("counties", { params: params });
    return response;
}

export async function getCountyById(id) {
    const response = await axiosAPI.get(`counties` + id);
    return response;
}

// district-county/{id}

export async function postCounty(data) {
    const response = await axiosAPI.post(`counties`, data);
    return response;
}

export async function updateCounty(id, data) {
    const response = await axiosAPI.put(`counties/${id}`, data);
    return response;
}

export async function deleteCountyById(id) {
    const response = await axiosAPI.delete(`counties/${id}`);
    return response;
}
