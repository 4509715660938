import React, { useState, useEffect, useRef } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "primereact/utils";

import setFieldTouched from "final-form-set-field-touched";
//
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";
import { Image } from "primereact/image";
import { Toast as PrimeReactToast } from "primereact/toast";

//
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

import { getAllRegions } from "../../../../services/locations/regions-service";
import { getAllDistricts } from "../../../../services/locations/districts-service";
import { getAllCounty } from "../../../../services/locations/county-service";
import { getAllSubcounty } from "../../../../services/locations/subcounty-service";
import { getAllParish } from "../../../../services/locations/parish-service";
import { getAllVillage } from "../../../../services/locations/village-service";
import { getAllSectors } from "../../../../services/sectors/sectors-service";

import useHandleQueryError from "../../../../hooks/useHandleQueryError";

function SummarizeFeedbackRowForm({ handleSubmit, productCategoryBrandData, initialData, ...props }) {
    console.log("🚀df ~ SummarizeFeedbackRowForm ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    //============ Auto Complete States =================
    const [dropdownRegions, setDropdownRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);

    const [dropdownDistricts, setDropdownDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const [dropdownCounties, setDropdownCounties] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState(null);

    const [dropdownSubcounties, setDropdownSubcounties] = useState([]);
    const [selectedSubcounty, setSelectedSubcounty] = useState(null);

    const [dropdownParishes, setDropdownParishes] = useState([]);
    const [selectedParish, setSelectedParish] = useState(null);

    const [dropdownVillages, setDropdownVillages] = useState([]);
    const [selectedVillage, setSelectedVillage] = useState(null);

    // Fetch regions
    const regionsQuery = useQuery({
        queryKey: "regions",
        queryFn: getAllRegions,
    });
    useHandleQueryError(regionsQuery?.isError, regionsQuery?.error);

    // Fetch districts based on selected region
    const districtsQuery = useQuery({
        queryKey: ["districts", selectedRegion],
        queryFn: () => getAllDistricts({ region_id: selectedRegion?.id }),
        enabled: !!selectedRegion, // Ensures the query is run only when a region is selected
    });
    useHandleQueryError(districtsQuery?.isError, districtsQuery?.error);

    // Fetch counties based on selected district
    const countiesQuery = useQuery({
        queryKey: ["counties", selectedDistrict],
        queryFn: () => getAllCounty({ district_id: selectedDistrict?.id }),
        enabled: !!selectedDistrict,
    });
    useHandleQueryError(countiesQuery?.isError, countiesQuery?.error);

    // Fetch subcounties based on selected county
    const subcountiesQuery = useQuery({
        queryKey: ["subcounties", selectedCounty],
        queryFn: () => getAllSubcounty({ county_id: selectedCounty?.id }),
        enabled: !!selectedCounty,
    });
    useHandleQueryError(subcountiesQuery?.isError, subcountiesQuery?.error);

    // Fetch parishes based on selected subcounty
    const parishesQuery = useQuery({
        queryKey: ["parishes", selectedSubcounty],
        queryFn: () => getAllParish({ sub_county_id: selectedSubcounty?.id }),
        enabled: !!selectedSubcounty,
    });
    useHandleQueryError(parishesQuery?.isError, parishesQuery?.error);

    // Fetch villages based on selected parish
    const villagesQuery = useQuery({
        queryKey: ["villages", selectedParish],
        queryFn: () => getAllVillage({ parish_id: selectedParish?.id }),
        enabled: !!selectedParish,
    });
    useHandleQueryError(villagesQuery?.isError, villagesQuery?.error);

    //=========================

    const [selectedSector, setSelectedSector] = useState(null);
    const [dropdownSectors, setDropdownSectors] = useState([]);

    const sectorsQuery = useQuery({
        queryKey: ["sectors"],
        queryFn: () => getAllSectors(),
    });

    // Use the custom hook to handle errors for sectorsQuery
    useHandleQueryError(sectorsQuery?.isError, sectorsQuery?.error);

    //
    const validate = (values) => {
        const errors = {};

        // if (!values.is_anonymous) errors.is_anonymous = "is anonymous is required";
        // Validate 'name'

        if (!values.title) errors.title = "title is required";
        if (!values.message) errors.message = "message is required";

        // Validate 'status'
        if (!values.status) errors.status = "Status is required";

        if (!values.latitude) errors.latitude = "latitude is required";

        if (!values.longitude) errors.longitude = "longitude is required";

        // Validate 'details'
        if (!values.sector) errors.sector = "sector are required";

        // Validate 'regions'
        if (!values?.region) {
            errors.region = "Region must be selected";
        }

        // Validate 'districts'
        if (values?.region && !values?.district) {
            errors.district = "District must be selected";
        }

        // Validate 'counties'
        if (values?.district && !values?.county) {
            errors.county = "County must be selected";
        }

        // Validate 'subcounties'
        if (values?.county && !values?.subcounty) {
            errors.subcounty = "Subcounty must be selected";
        }

        // Validate 'parishes'
        if (values?.subcounty && !values?.parish) {
            errors.parish = "Parish must be selected";
        }

        // Validate 'villages'
        if (values?.parish && !values?.village) {
            errors.village = "Village must be selected";
        }

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please first fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="title">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="title">Title</label>
                                        <InputText {...input} id="title" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="message">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="message">Message</label>
                                        <InputTextarea {...input} id="message" rows={5} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Status</label>
                                        <Dropdown
                                            {...input}
                                            options={[
                                                { id: "active", name: "Active" },
                                                { id: "deactive", name: "Deactive" },
                                            ].map((role) => ({ label: role.name, value: role.id }))}
                                            placeholder="Select a Status"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="feedback_submit_status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="feedback_submit_status">Feedback Submit Status</label>
                                        <Dropdown
                                            {...input}
                                            options={[
                                                { label: "Draft", value: "draft" },
                                                { label: "Submitted", value: "submitted" },
                                            ]}
                                            placeholder="Select status"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            {/* Location fields */}
                            <Field name="latitude">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="latitude">Latitude</label>
                                        <InputText {...input} id="latitude" type="number" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="longitude">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="longitude">Longitude</label>
                                        <InputText {...input} id="longitude" type="number" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            {/* Add more fields for accuracy, altitude, speed_accuracy */}
                            {/* Administrative division fields */}
                            {/* <Field name="region_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="region_id">Region</label>
                                        <Dropdown {...input} options={regions} optionLabel="name" placeholder="Select a region" className={classNames({ 'p-invalid': meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <Field name="sector">
                                {({ input, meta }) => {
                                    const sectorsData = sectorsQuery?.data?.data?.data || [];
                                    const fetchSuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = sectorsData.filter((sector) => sector?.name?.toLowerCase().includes(query));
                                        setDropdownSectors(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="sector">Sector</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false} // Set to false for single selection
                                                suggestions={dropdownSectors}
                                                completeMethod={fetchSuggestions}
                                                field="name"
                                                value={selectedSector} // Single value for sector
                                                onChange={(e) => {
                                                    setSelectedSector(e.value);
                                                    input.onChange(e.value);
                                                }}
                                                dropdown={true}
                                                disabled={sectorsQuery?.isLoading}
                                                placeholder="Select sector"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* Show loading spinner if sectorsQuery is loading */}
                                            {sectorsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>
                            {/* <Field name="details">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="details">details</label>
                                        <InputTextarea {...input} rows={5} cols={30} id="details" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            {/* Region Field */}
                            <Field name="region">
                                {({ input, meta }) => {
                                    const regionsData = regionsQuery?.data?.data?.data || [];
                                    const fetchRegionSuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = regionsData.filter((region) => region?.name?.toLowerCase().includes(query));
                                        setDropdownRegions(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="region">Region</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false}
                                                suggestions={dropdownRegions}
                                                completeMethod={fetchRegionSuggestions}
                                                field="name"
                                                value={selectedRegion}
                                                onChange={(e) => {
                                                    setSelectedRegion(e.value);
                                                    input.onChange(e.value);

                                                    // Reset dependent fields
                                                    setSelectedDistrict(null);
                                                    setDropdownDistricts([]);
                                                    setSelectedCounty(null);
                                                    setDropdownCounties([]);
                                                    setSelectedSubcounty(null);
                                                    setDropdownSubcounties([]);
                                                    setSelectedParish(null);
                                                    setDropdownParishes([]);
                                                    setSelectedVillage(null);
                                                    setDropdownVillages([]);

                                                    // Reset form fields for the dependent fields
                                                    form.change("district", null);
                                                    form.change("county", null);
                                                    form.change("subcounty", null);
                                                    form.change("parish", null);
                                                    form.change("village", null);
                                                }}
                                                dropdown={true}
                                                disabled={regionsQuery?.isLoading}
                                                placeholder="Select region"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* Show loading spinner if regionsQuery is loading */}
                                            {regionsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <Field name="district">
                                {({ input, meta }) => {
                                    const districtsData = districtsQuery?.data?.data?.data || [];
                                    const fetchDistrictSuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = districtsData.filter((district) => district?.name?.toLowerCase().includes(query));
                                        setDropdownDistricts(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="district">District</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false}
                                                suggestions={dropdownDistricts}
                                                completeMethod={fetchDistrictSuggestions}
                                                field="name"
                                                value={selectedDistrict}
                                                onChange={(e) => {
                                                    setSelectedDistrict(e.value);
                                                    input.onChange(e.value);

                                                    // Reset dependent fields
                                                    setSelectedCounty(null);
                                                    setDropdownCounties([]);
                                                    setSelectedSubcounty(null);
                                                    setDropdownSubcounties([]);
                                                    setSelectedParish(null);
                                                    setDropdownParishes([]);
                                                    setSelectedVillage(null);
                                                    setDropdownVillages([]);

                                                    // Reset form fields for the dependent fields
                                                    form.change("county", null);
                                                    form.change("subcounty", null);
                                                    form.change("parish", null);
                                                    form.change("village", null);
                                                }}
                                                dropdown={true}
                                                disabled={!selectedRegion}
                                                placeholder="Select district"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                            {/* Show loading spinner if districtsQuery is loading */}
                                            {districtsQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            {/* County Field */}
                            <Field name="county">
                                {({ input, meta }) => {
                                    const countiesData = countiesQuery?.data?.data?.data || [];
                                    const fetchCountySuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = countiesData.filter((county) => county?.name?.toLowerCase().includes(query));
                                        setDropdownCounties(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="county">County</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false}
                                                suggestions={dropdownCounties}
                                                completeMethod={fetchCountySuggestions}
                                                field="name"
                                                value={selectedCounty}
                                                onChange={(e) => {
                                                    setSelectedCounty(e.value);

                                                    input.onChange(e.value);

                                                    // Reset dependent fields

                                                    setSelectedSubcounty(null);
                                                    setDropdownSubcounties([]);
                                                    setSelectedParish(null);
                                                    setDropdownParishes([]);
                                                    setSelectedVillage(null);
                                                    setDropdownVillages([]);

                                                    // Reset form fields for the dependent fields

                                                    form.change("subcounty", null);
                                                    form.change("parish", null);
                                                    form.change("village", null);
                                                }}
                                                dropdown={true}
                                                disabled={!selectedDistrict || countiesQuery?.isLoading}
                                                placeholder="Select county"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {/* Show loading spinner if regionsQuery is loading */}
                                            {countiesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            {/* Subcounty Field */}
                            <Field name="subcounty">
                                {({ input, meta }) => {
                                    const subcountiesData = subcountiesQuery?.data?.data?.data || [];
                                    const fetchSubcountySuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = subcountiesData.filter((subcounty) => subcounty?.name?.toLowerCase().includes(query));
                                        setDropdownSubcounties(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="subcounty">Subcounty</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false}
                                                suggestions={dropdownSubcounties}
                                                completeMethod={fetchSubcountySuggestions}
                                                field="name"
                                                value={selectedSubcounty}
                                                onChange={(e) => {
                                                    setSelectedSubcounty(e.value);

                                                    input.onChange(e.value);

                                                    // Reset dependent fields

                                                    setSelectedParish(null);
                                                    setDropdownParishes([]);
                                                    setSelectedVillage(null);
                                                    setDropdownVillages([]);

                                                    // Reset form fields for the dependent fields

                                                    form.change("parish", null);
                                                    form.change("village", null);
                                                }}
                                                dropdown={true}
                                                disabled={!selectedCounty || subcountiesQuery?.isLoading}
                                                placeholder="Select subcounty"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {/* Show loading spinner if subcountiesQuery is loading */}
                                            {subcountiesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            {/* Parish Field */}
                            <Field name="parish">
                                {({ input, meta }) => {
                                    const parishesData = parishesQuery?.data?.data?.data || [];
                                    const fetchParishSuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = parishesData.filter((parish) => parish?.name?.toLowerCase().includes(query));
                                        setDropdownParishes(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="parish">Parish</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false}
                                                suggestions={dropdownParishes}
                                                completeMethod={fetchParishSuggestions}
                                                field="name"
                                                value={selectedParish}
                                                onChange={(e) => {
                                                    setSelectedParish(e.value);

                                                    input.onChange(e.value);

                                                    setSelectedVillage(null);
                                                    setDropdownVillages([]);

                                                    // Reset form fields for the dependent fields

                                                    form.change("village", null);
                                                }}
                                                dropdown={true}
                                                disabled={!selectedSubcounty || parishesQuery?.isLoading}
                                                placeholder="Select parish"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {/* Show loading spinner if parishesQuery is loading */}
                                            {parishesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            {/* Village Field */}
                            <Field name="village">
                                {({ input, meta }) => {
                                    const villagesData = villagesQuery?.data?.data?.data || [];
                                    const fetchVillageSuggestions = (event) => {
                                        let query = event.query.toLowerCase();
                                        let filtered = villagesData.filter((village) => village?.name?.toLowerCase().includes(query));
                                        setDropdownVillages(filtered);
                                    };

                                    return (
                                        <div className="p-field m-4">
                                            <label htmlFor="village">Village</label>
                                            <AutoComplete
                                                {...input}
                                                multiple={false}
                                                suggestions={dropdownVillages}
                                                completeMethod={fetchVillageSuggestions}
                                                field="name"
                                                value={selectedVillage}
                                                onChange={(e) => {
                                                    setSelectedVillage(e.value);
                                                    input.onChange(e.value);
                                                }}
                                                dropdown={true}
                                                disabled={!selectedParish || villagesQuery?.isLoading}
                                                placeholder="Select village"
                                                className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            {/* Show loading spinner if villagesQuery is loading */}
                                            {villagesQuery?.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        </div>
                                    );
                                }}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    style={{ minWidth: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default SummarizeFeedbackRowForm;
