import axiosAPI from "../axiosApi";

export async function getAllChoices(params = {}) {
    const response = await axiosAPI.get("choices", { params: params });
    return response;
}

export async function getChoiceById(id) {
    const response = await axiosAPI.get(`choices/` + id);
    return response;
}

export async function postChoice(data) {
    const response = await axiosAPI.post(`choices`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateChoice(id, data) {
    const response = await axiosAPI.post(`choices/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteChoiceById(id) {
    const response = await axiosAPI.delete(`choices/${id}`);
    return response;
}
