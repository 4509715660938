import React from "react";
import ListPage from "./ListPage";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../components/general_components/BreadcrumbNav";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const createBreadCrump = () => {};
//
function CountryPage() {
    return (
        <div>
            <BreadcrumbNav />

            <ListPage />
        </div>
    );
}

export default CountryPage;
