import axiosAPI from "../axiosApi";

// export async function getAllFeedbacks(params = {}) {
//     const response = await axiosAPI.get("feedback", { params: params });
//     return response;
// }

export async function postToBulkEvaluateSummarisedFeedback(data) {
    const response = await axiosAPI.post(`aagca-bulk-evaluate-summarised-feedback`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}
