import axiosAPI from "../axiosApi";

// Fetch all feedback statistics
export async function getAllFeedbackStatistics(params = {}) {
    const response = await axiosAPI.get("all-feedback-statistics", { params: params });
    return response;
}

export async function getAllNewFeedbackStatistics(params = {}) {
    const response = await axiosAPI.get("all-feedback-new-statistics", { params: params });
    return response;
}

// Fetch non-audit feedback statistics
export async function getNonAuditStatistics(params = {}) {
    const response = await axiosAPI.get("non-audit-statistics", { params: params });
    return response;
}

// Fetch auditable feedback statistics
export async function getAuditableStatistics(params = {}) {
    const response = await axiosAPI.get("auditable-statistics", { params: params });
    return response;
}

export async function getTaggedStatistics(params = {}) {
    const response = await axiosAPI.get("tagged-statistics", { params: params });
    return response;
}

// Fetch summarised issues statistics
export async function getSummarisedIssuesStatistics(params = {}) {
    const response = await axiosAPI.get("summarised-issues-statistics", { params: params });
    return response;
}

// Fetch statistics for summarised feedback assigned to the user
export async function getAssignedToMeSummarisedFeedbackStatistics(params = {}) {
    const response = await axiosAPI.get("assigned-to-me-summarised-feedback-statistics", { params: params });
    return response;
}

// Fetch rejected summarised feedback statistics
export async function getRejectedSummarisedFeedbackStatistics(params = {}) {
    const response = await axiosAPI.get("rejected-summarised-feedback-statistics", { params: params });
    return response;
}

// Fetch in-progress summarised feedback statistics
export async function getInProgressSummarisedFeedbackStatistics(params = {}) {
    const response = await axiosAPI.get("in-progress-summarised-feedback-statistics", { params: params });
    return response;
}

// Fetch done summarised feedback statistics
export async function getDoneSummarisedFeedbackStatistics(params = {}) {
    const response = await axiosAPI.get("done-summarised-feedback-statistics", { params: params });
    return response;
}

// Fetch failed summarised feedback statistics
export async function getFailedSummarisedFeedbackStatistics(params = {}) {
    const response = await axiosAPI.get("failed-summarised-feedback-statistics", { params: params });
    return response;
}

export async function getCompletedSummarisedFeedbackStatistics(params = {}) {
    const response = await axiosAPI.get("completed-summarised-feedback-statistics", { params: params });
    return response;
}

