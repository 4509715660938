import axiosAPI from "../axiosApi";

export async function getAllCountrys(params = {}) {
    const response = await axiosAPI.get("countries", { params: params });
    return response;
}

export async function getCountrysById(id) {
    const response = await axiosAPI.get(`countries/` + id);
    return response;
}

export async function postCountrys(data) {
    const response = await axiosAPI.post(`countries`, data);
    return response;
}

export async function updateCountrys(id, data) {
    const response = await axiosAPI.put(`countries/${id}`, data);
    return response;
}

export async function deleteCountryById(id) {
    const response = await axiosAPI.delete(`countries/${id}`);
    return response;
}
