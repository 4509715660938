import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { AutoComplete } from "primereact/autocomplete";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query"; // Assuming React Query is used for fetching data

import { getAllUsers, getUserById, getApproverRoles, createUser, updateUser, deleteUserById, getAssignableRoles } from "../../../../services/auth/user-service";
import { getAllDirectorates, getDirectorateById, postDirectorate, updateDirectorate, deleteDirectorateById } from "../../../../services/directorates/directorates-service";

import useHandleQueryError from "../../../../hooks/useHandleQueryError";

const AssignOfficersRow = ({ onSubmit }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    //
    const [dropdownDirectorates, setDropdownDirectorates] = useState([]);
    const [selectedDirectorates, setSelectedDirectorates] = useState([]);

    //
    const [dropdownUsers, setDropdownUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    // Fetch directorates using React Query
    const directoratesQuery = useQuery({
        queryKey: ["directorates"],
        queryFn: () => getAllDirectorates(),
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(directoratesQuery?.isError, directoratesQuery?.error);

    // Fetch users using React Query
    const usersQuery = useQuery({
        queryKey: ["users", selectedDirectorates],
        queryFn: () => getAllUsers({ directorates: selectedDirectorates }),
        enabled: selectedDirectorates.length > 0,
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(usersQuery?.isError, usersQuery?.error);

    const validate = (values) => {
        const errors = {};
        if (!values.directorates || !Array.isArray(values.directorates) || values.directorates.length === 0) {
            errors.directorates = "Please select at least one directorate";
        }
        if (!values.users || !Array.isArray(values.users) || values.users.length === 0) {
            errors.users = "Please select at least one user";
        }
        if (!values.instructions || values.instructions.trim() === "") {
            errors.instructions = "Please provide instructions";
        }
        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            onSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    // Filter method for directorates AutoComplete
    const fetchDirectorateSuggestions = (event) => {
        const query = event.query.toLowerCase();
        const filtered = directoratesQuery?.data?.data?.data?.filter((directorate) => directorate?.name?.toLowerCase().includes(query));
        setDropdownDirectorates(filtered);
    };

    // Filter method for users AutoComplete
    const fetchUserSuggestions = (event) => {
        const query = event.query.toLowerCase();
        const filtered = usersQuery?.data?.data?.data?.filter((user) => user?.name?.toLowerCase().includes(query));
        setDropdownUsers(filtered);
    };

    return (
        <div className="card p-fluid">
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        {/* Directorates AutoComplete */}
                        <Field name="directorates">
                            {({ input, meta }) => (
                                <div className="field m-4">
                                    <label htmlFor="directorates">Directorates</label>
                                    <AutoComplete
                                        {...input}
                                        multiple
                                        suggestions={dropdownDirectorates}
                                        completeMethod={fetchDirectorateSuggestions}
                                        field="name"
                                        value={selectedDirectorates}
                                        onChange={(e) => {
                                            setSelectedDirectorates(e.value);
                                            input.onChange(e.value);

                                            // Reset users on change of directorates
                                            setSelectedUsers([]);
                                            setDropdownUsers([]);
                                            form.change("users", []); // Reset Final Form state for users
                                        }}
                                        dropdown
                                        placeholder="Select directorates"
                                        className={meta.touched && meta.error ? "p-invalid" : ""}
                                    />
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                    {/* Show loading spinner while fetching directorates */}
                                    {directoratesQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                </div>
                            )}
                        </Field>

                        {/* Users AutoComplete */}
                        <Field name="users">
                            {({ input, meta }) => (
                                <div className="field m-4">
                                    <label htmlFor="users">Users</label>
                                    <AutoComplete
                                        {...input}
                                        multiple
                                        suggestions={dropdownUsers}
                                        completeMethod={fetchUserSuggestions}
                                        field="name"
                                        value={selectedUsers}
                                        onChange={(e) => {
                                            setSelectedUsers(e.value);
                                            input.onChange(e.value);
                                        }}
                                        dropdown
                                        placeholder="Select users"
                                        className={meta.touched && meta.error ? "p-invalid" : ""}
                                    />
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}

                                    {/* Show loading spinner while fetching users */}
                                    {usersQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                </div>
                            )}
                        </Field>

                        {/* Instructions TextArea */}
                        <Field name="instructions">
                            {({ input, meta }) => (
                                <div className="field m-4">
                                    <label htmlFor="instructions">Instructions</label>
                                    <InputTextarea {...input} rows={5} cols={30} autoResize placeholder="Provide instructions" />
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>

                        <Button type="submit" label="Submit" className="p-button-primary" />
                    </form>
                )}
            />

            {/* Confirmation Dialog */}
            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                style={{ width: "350px" }}
                modal
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus />
                        <Button label="No" icon="pi pi-times" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                <p>Are you sure you want to submit this action?</p>
            </Dialog>
        </div>
    );
};

export default AssignOfficersRow;
