import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const useHandleQueryError = (isError, error) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();

    // Memoize the error object
    const memoizedError = useMemo(() => error, [error]);

    useEffect(() => {
        if (isError) {
            console.log("Error fetching List of data:", memoizedError);

            // Check for a 401 Unauthorized error with an "Unauthenticated." message
            if (memoizedError?.response?.status === 401 && memoizedError?.response?.data?.message === "Unauthenticated.") {
                // Only clear tokens and navigate if not already on the login page
                if (!["/login", "/sign-up", "/reset-password"].includes(location.pathname)) {
                    toast.warning("Session expired. Please log in again.");
                    queryClient.resetQueries({ queryKey: ["logged-in-user"] });
                    queryClient.removeQueries({ queryKey: ["logged-in-user"] });
                    queryClient.clear();
                    navigate("/login"); // Redirect to the login page
                }
            } else if (memoizedError?.response?.data?.message) {
                toast.error(memoizedError.response.data.message);
            } else if (!memoizedError?.response) {
                toast.warning("Check Your Internet Connection Please");
            } else {
                toast.error("An Error Occurred. Please Contact Admin");
            }
        }
    }, [isError, memoizedError]);

    return;
};

export default useHandleQueryError;
