import React, { useState, useContext, useEffect, useRef } from "react";
import numeral from "numeral";

//
import {
    getAllFeedbackStatistics,
    getNonAuditStatistics,
    getAuditableStatistics,
    getSummarisedIssuesStatistics,
    getAssignedToMeSummarisedFeedbackStatistics,
    getRejectedSummarisedFeedbackStatistics,
    getInProgressSummarisedFeedbackStatistics,
    getDoneSummarisedFeedbackStatistics,
    getFailedSummarisedFeedbackStatistics,
} from "../../../services/dashboard-statistics/statistics-cards-service";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Grid, Stack } from "@mui/material";

//lotties
import MaterialUiLoaderLottie from "../../../assets/lotties/oag-lotties/material-ui-loading-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/oag-lotties/snail-error-lottie.json";
import Lottie from "lottie-react";
import moment from "moment";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";

//
import StatisticCard from "./widgets/StatisticCard";

const AssignedToMeSummarisedFeedbackStatisticsPage = () => {
    //==================== end chart filters ===================

    // Products
    const StatisticsDataQuery = useQuery({
        disable: false,
        queryKey: ["statistics-cards-assigned-to-me-summarised-feedback"],
        queryFn: () => getAssignedToMeSummarisedFeedbackStatistics({}),
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(StatisticsDataQuery?.isError, StatisticsDataQuery?.error);

    const StatisticsData = StatisticsDataQuery?.data?.data;
    console.log("🚀 ~ AssignedToMeSummarisedFeedbackStatisticsPage ~ StatisticsData:", StatisticsData);

    //========================

    return (
        <>
            <Grid item xs={12} md={6} lg={3}>
                {StatisticsDataQuery?.isLoading ? (
                    <div className="col-12">
                        {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ maxWidth: "100%" }}>
                                {/* <Lottie animationData={RobotProcessorLottie} loop={true} style={{ height: "300px" }} autoplay={true} /> */}
                                <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                            </div>
                        </div>
                    </div>
                ) : StatisticsDataQuery?.isError ? (
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "400px" }}>
                            <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                        </div>
                    </div>
                ) : (
                    <>
                        <StatisticCard title={StatisticsData?.title} icon={StatisticsData?.icon} color={StatisticsData?.color} count={StatisticsData?.count} message={StatisticsData?.message} />
                    </>
                )}
            </Grid>
        </>
    );
};

export default AssignedToMeSummarisedFeedbackStatisticsPage;
