import React from "react";
import CardsPage from "./statistics-cards/CardsPage";
import MapsPage from "./maps/MapsPage";
import BarChartsPage from "./bar-charts/BarChartsPage";

function dashboardPage() {
    return (
        <div>
            <div className="col-12">
                <CardsPage />
            </div>
            <div className="col-12">
                <MapsPage />
            </div>
            <div className="col-12">
                <BarChartsPage />
            </div>
        </div>
    );
}

export default dashboardPage;
