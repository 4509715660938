import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog"; // PrimeReact Dialog component
import FileDownload from "@mui/icons-material/FileDownload"; // MUI download icon
import { Image } from "primereact/image";

const FeedbackAttachments = ({ originalFeedbacksData }) => {
    const [selectedImage, setSelectedImage] = useState(null); // State to manage the clicked image
    const [dialogVisible, setDialogVisible] = useState(false); // State to handle dialog visibility

    // Function to handle attachment click
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setDialogVisible(true);
    };

    // Function to determine the attachment type and render accordingly
    const renderAttachment = (attachment) => {
        const { type, file_path } = attachment;
        const lowerCaseType = type ? type.toLowerCase() : "";

        const getFileName = (filePath) => {
            return filePath.split("/").pop();
        };

        const fileName = getFileName(file_path);
        const fileExtension = file_path.substring(file_path.lastIndexOf(".") + 1).toLowerCase();

        // Check if it's an image
        if (lowerCaseType === "pictures" || lowerCaseType === "image" || lowerCaseType.includes("image")) {
            return (
                <>
                    {/* <Image src={`${process.env.REACT_APP_BASE_URL}${file_path}`} alt="Attachment" width="150" preview downloadable /> */}
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}${file_path}`}
                        alt="Attachment"
                        width="150"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleImageClick(`${process.env.REACT_APP_BASE_URL}${file_path}`)} // Open dialog on click
                    />
                </>
            );
        }

        // Check if it's a video
        if (lowerCaseType === "video" || lowerCaseType.includes("video")) {
            return (
                <video width="320" height="240" controls>
                    <source src={`${process.env.REACT_APP_BASE_URL}${file_path}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        }

        // Check if it's a document (PDF)
        if (lowerCaseType === "document" || lowerCaseType === "application/pdf") {
            return (
                <a href={`${process.env.REACT_APP_BASE_URL}${file_path}`} target="_blank" rel="noopener noreferrer" download>
                    <Button label={fileName} icon="pi pi-file-pdf" />
                </a>
            );
        }

        // Check if it's an audio file
        if (lowerCaseType === "audio") {
            return (
                <audio controls>
                    <source src={`${process.env.REACT_APP_BASE_URL}${file_path}`} type="audio/mp3" />
                    Your browser does not support the audio tag.
                </audio>
            );
        }

        // Default: File Download for other types
        return (
            <a href={`${process.env.REACT_APP_BASE_URL}${file_path}`} download>
                <Button label={fileName} icon={<FileDownload />} />
            </a>
        );
    };

    return (
        <div>
            <DataTable
                value={originalFeedbacksData}
                paginator
                rows={5}
                //  rowsPerPageOptions={[5, 10, 25, 50]}
                responsiveLayout="scroll"
            >
                <Column field="feedback.feedback_no" header="Feedback No" />
                {/* <Column field="feedback.message" header="Message" /> */}
                <Column
                    header="Attachment"
                    body={(rowData) => {
                        const attachments = rowData?.feedback?.attachments; // Get the attachments array

                        console.log("🚀 ~ FeedbackAttachments ~ attachments:", attachments);
                        const attachment = Array.isArray(attachments) && attachments.length > 0 ? attachments[0] : null; // Check if it's an array and get the first attachment
                        return attachment ? renderAttachment(attachment) : "No Attachment"; // Render if exists
                    }}
                />
            </DataTable>

            {/* Dialog for displaying image */}
            <Dialog header="Attachment Preview" visible={dialogVisible} maximizable onHide={() => setDialogVisible(false)} style={{ zIndex: "3000" }}>
                <div style={{ width: "100%", height: "100%", display: "flex", alighItems: "center", justifyContent: "center" }}>{selectedImage && <img src={selectedImage} alt="Attachment" style={{ width: "100%" }} />}</div>
            </Dialog>
        </div>
    );
};

export default FeedbackAttachments;
