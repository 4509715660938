import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import numeral from "numeral";

const StatisticCard = ({ count, title, color = "blue", icon = "pi-star-fill", message, ...props }) => {
    const formatNumber = (number) => {
        return numeral(number).format("0.[00]a"); // e.g., 3.5k, 3.45m, 3.4b
    };

    return (
        <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
                <div>
                    <Tooltip title={message} arrow>
                        <span className="block text-500 font-medium mb-3">{title}</span>
                    </Tooltip>
                    <div className="text-900 font-medium text-xl">{formatNumber(count)}</div>
                </div>
                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                    <i className={`pi ${icon} text-${color}-500 text-xl`} />
                </div>
            </div>
            {/* <span className="text-green-500 font-medium">24 new </span>
            <span className="text-500">since last visit</span> */}
        </div>
    );
};

export default StatisticCard;
