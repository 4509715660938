import React, { useState } from "react";
import RowForm2 from "./widgets/RowForm2";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

function ViewForm(props) {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Dialog header="View Choice" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <p>View Data Below</p>
            {/* Disable the form by passing a readOnly prop */}
            <RowForm2 initialData={props.rowData} readOnly={true} />

            {isLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default ViewForm;
