import axiosAPI from "../axiosApi";

export async function getAllGroupTypes(params = {}) {
    const response = await axiosAPI.get("groupTypes", { params: params });
    return response;
}

export async function getGroupTypeById(id) {
    const response = await axiosAPI.get(`groupTypes/` + id);
    return response;
}

export async function postGroupType(data) {
    const response = await axiosAPI.post(`groupTypes`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateGroupType(id, data) {
    const response = await axiosAPI.post(`groupTypes/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteGroupTypeById(id) {
    const response = await axiosAPI.delete(`groupTypes/${id}`);
    return response;
}
