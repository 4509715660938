import React from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";

import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { FileDownload } from "@mui/icons-material";
import { Grid, Typography, Card } from "@mui/material";
import { Accordion, AccordionTab } from "primereact/accordion";

function ServicesFeedbackDetailsModal({ feedbackDetailData, ...props }) {
    console.log("🚀 ~ ServicesFeedbackDetailsModal ~ feedbackDetailData:", feedbackDetailData);
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a"); // Example format
    };

    // Function to display status with color
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "active":
                severity = "success"; // green
                break;
            case "draft":
                severity = "info"; // blue
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    // Function to determine the attachment type and render accordingly
    // Function to determine the attachment type and render accordingly
    const renderAttachment = (attachment) => {
        const { type, file_path } = attachment;
        // Handle case where type is null or undefined
        const lowerCaseType = type ? type.toLowerCase() : "";

        const getFileName = (filePath) => {
            // Split the path by '/' and get the last element
            return filePath.split("/").pop();
        };

        const fileName = getFileName(file_path);

        // Extract file extension from file_path
        const fileExtension = file_path.substring(file_path.lastIndexOf(".") + 1).toLowerCase();

        // Check if it's an image (handles both "Pictures" and "image")
        if (lowerCaseType === "pictures" || lowerCaseType === "image" || lowerCaseType.includes("image")) {
            return <Image src={`${process.env.REACT_APP_BASE_URL}${file_path}`} alt="Attachment" width="150" preview />;
        }

        // Check if it's a video (handles both "Video" and "video")
        if (lowerCaseType === "video" || lowerCaseType.includes("video")) {
            return (
                <video width="320" height="240" controls>
                    <source
                        src={`${process.env.REACT_APP_BASE_URL}${file_path}`}
                        type="video/mp4"
                        // type={`video/${fileExtension}`}
                    />
                    Your browser does not support the video tag.
                </video>
            );
        }

        // Check if it's a document (PDF)
        if (lowerCaseType === "document" || lowerCaseType === "application/pdf") {
            return (
                <a href={`${process.env.REACT_APP_BASE_URL}${file_path}`} target="_blank" rel="noopener noreferrer" download>
                    <Button label={fileName} icon="pi pi-file-pdf" />
                </a>
            );
        }

        // Check if it's an audio file
        if (lowerCaseType === "audio") {
            return (
                <audio controls>
                    <source
                        src={`${process.env.REACT_APP_BASE_URL}${file_path}`}
                        type="audio/mp3"
                        // type={`audio/${fileExtension}`}
                    />
                    Your browser does not support the audio tag.
                </audio>
            );
        }

        // Default: File Download for other types
        return (
            <a href={`${process.env.REACT_APP_BASE_URL}${file_path}`} download>
                <Button label="Download fileName" icon={<FileDownload />} />
            </a>
        );
    };

    const getServicesFeedbackSeverityColor = (status) => {
        switch (status?.toLowerCase()) {
            case "submitted":
                return "info"; // Blue
            case "in progress":
                return "warning"; // Yellow/Orange
            case "resolved":
                return "success"; // Green
            default:
                return "secondary"; // Default gray
        }
    };

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Feedback Details</div>
                </div>
            }
            visible={props.show}
            // style={{ minWidth: "60vw" }}
            onHide={props.onHide}
            maximizable
        >
            {/* Feedback Information */}
            <div className="card" style={{ marginBottom: "1rem" }} title="Feedback Information">
                <Grid container spacing={2}>
                    {/* Column 1 */}
                    <Grid item sm={12} md={6} lg={3}>
                        {/* <Typography variant="body1">
                            <strong>Feedback Number:</strong> <Tag value={feedbackDetailData?.feedback_no ? feedbackDetailData?.feedback_no : "N/A"} severity={getServicesFeedbackSeverityColor(feedbackDetailData?.spatie_current_status?.name)} />
                        </Typography> */}
                        <Typography variant="body1">
                            <strong>Anonymous:</strong> {feedbackDetailData?.is_anonymous ? "Yes" : "No"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Topic:</strong> {feedbackDetailData?.topic || "N/A"}
                        </Typography>

                        <Typography variant="body1">
                            <strong>Created By:</strong> {feedbackDetailData?.created_by?.name || "N/A"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Created At:</strong> {feedbackDetailData?.created_at ? moment(feedbackDetailData.created_at).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
                        </Typography>

                        {/* <Typography variant="body1">
                            <strong>Accuracy:</strong> {feedbackDetailData?.accuracy || "N/A"}
                        </Typography> */}
                    </Grid>

                    {/* Column 2 */}
                    <Grid item sm={12} md={6} lg={3}>
                        {/* <Typography variant="body1">
                            <strong>Status:</strong> {tagStatus(feedbackDetailData?.status)}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Submit Status:</strong> {tagStatus(feedbackDetailData?.feedback_submit_status)}
                        </Typography> */}

                        <Typography variant="body1">
                            <strong>Updated By:</strong> {feedbackDetailData?.updated_by?.name || "N/A"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Updated At:</strong> {feedbackDetailData?.updated_at ? moment(feedbackDetailData.updated_at).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
                        </Typography>
                    </Grid>

                    {/* Full Width Row for Message */}
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                            <strong>Message:</strong> {feedbackDetailData?.message || "No message provided."}
                        </Typography>
                    </Grid>

                    {/* Spatie Status */}

                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <strong>Current Status:</strong> <Tag value={feedbackDetailData?.spatie_current_status?.name ?? "Unknown"} severity={getServicesFeedbackSeverityColor(feedbackDetailData?.spatie_current_status?.name)} />
                        </Typography>
                        <Typography variant="body1">
                            <strong>Comment:</strong> {feedbackDetailData?.spatie_current_status?.reason}
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            {/* Feedback Attachments */}
            <div className="card" title="Attachments" style={{ marginTop: "1rem" }}>
                <Typography variant="body1">
                    <strong>Attachments:</strong>
                </Typography>

                <Accordion multiple>
                    {/* Pictures Section */}
                    {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("pictures")).length > 0 && (
                        <AccordionTab header="Pictures">
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("pictures")).length > 0 ? (
                                    feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("pictures")).map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                ) : (
                                    <p>No pictures available</p>
                                )}
                            </div>
                        </AccordionTab>
                    )}

                    {/* Audio Section */}
                    {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("audio")).length > 0 && (
                        <AccordionTab header="Audio">
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("audio")).length > 0 ? (
                                    feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("audio")).map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                ) : (
                                    <p>No audio files available</p>
                                )}
                            </div>
                        </AccordionTab>
                    )}

                    {/* Videos Section */}
                    {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("video")).length > 0 && (
                        <AccordionTab header="Videos">
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("video")).length > 0 ? (
                                    feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("video")).map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                ) : (
                                    <p>No videos available</p>
                                )}
                            </div>
                        </AccordionTab>
                    )}

                    {/* Documents Section */}
                    {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase() === "application/pdf" || attachment.type?.toLowerCase() === "document").length > 0 && (
                        <AccordionTab header="Documents">
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase() === "application/pdf" || attachment.type?.toLowerCase() === "document").length > 0 ? (
                                    feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase() === "application/pdf" || attachment.type?.toLowerCase() === "document").map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                ) : (
                                    <p>No documents available</p>
                                )}
                            </div>
                        </AccordionTab>
                    )}

                    {/* Other Files Section */}

                    {feedbackDetailData?.attachments?.filter(
                        (attachment) =>
                            !attachment.type?.toLowerCase().includes("pictures") && !attachment.type?.toLowerCase().includes("audio") && !attachment.type?.toLowerCase().includes("video") && attachment.type?.toLowerCase() !== "application/pdf" && attachment.type?.toLowerCase() !== "document"
                    ).length > 0 && (
                        <AccordionTab header="Others">
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                {feedbackDetailData?.attachments?.filter(
                                    (attachment) =>
                                        !attachment.type?.toLowerCase().includes("pictures") &&
                                        !attachment.type?.toLowerCase().includes("audio") &&
                                        !attachment.type?.toLowerCase().includes("video") &&
                                        attachment.type?.toLowerCase() !== "application/pdf" &&
                                        attachment.type?.toLowerCase() !== "document"
                                ).length > 0 ? (
                                    feedbackDetailData?.attachments
                                        ?.filter(
                                            (attachment) =>
                                                !attachment.type?.toLowerCase().includes("pictures") &&
                                                !attachment.type?.toLowerCase().includes("audio") &&
                                                !attachment.type?.toLowerCase().includes("video") &&
                                                attachment.type?.toLowerCase() !== "application/pdf" &&
                                                attachment.type?.toLowerCase() !== "document"
                                        )
                                        .map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                ) : (
                                    <p>No other files available</p>
                                )}
                            </div>
                        </AccordionTab>
                    )}
                </Accordion>
            </div>
        </Dialog>
    );
}

export default ServicesFeedbackDetailsModal;
