import React, { createContext, useState, useContext, useEffect } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    //
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);

    //

    const [menuClick, setMenuClick] = useState(false);

    // let menuClick = false;
    // let mobileTopbarMenuClick = false;\

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    //================== toggle menu =============

    const onToggleMenuClick = (event) => {
        setMenuClick(true);

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        setMenuClick(true);
    };

    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    //

    return (
        <SidebarContext.Provider
            value={{
                menuClick,
                setMenuClick,

                //==============side bar
                layoutMode,
                setLayoutMode,
                layoutColorMode,
                setLayoutColorMode,
                inputStyle,
                setInputStyle,
                ripple,
                setRipple,
                staticMenuInactive,
                setStaticMenuInactive,
                overlayMenuActive,
                setOverlayMenuActive,
                onToggleMenuClick,
                onSidebarClick,
                mobileMenuActive,
                setMobileMenuActive,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error("useSidebar must be used within a SidebarProvider");
    }
    return context;
};
