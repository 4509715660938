import axiosAPI from "../axiosApi";

export async function getAllSectors(params = {}) {
    const response = await axiosAPI.get("sectors", { params: params });
    return response;
}

export async function getSectorById(id) {
    const response = await axiosAPI.get(`sectors/` + id);
    return response;
}

export async function postSector(data) {
    const response = await axiosAPI.post(`sectors`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateSector(id, data) {
    const response = await axiosAPI.post(`sectors/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteSectorById(id) {
    const response = await axiosAPI.delete(`sectors/${id}`);
    return response;
}
