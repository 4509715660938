import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

const Polls = () => {
    return (
        <div className="polls-videos">
            <h2>Polls Tutorials</h2>
            <p>Learn how to create a poll and how to respond to a poll through the CF app by watching the videos below.</p>

            <Accordion multiple>
                {/* Video 1: How to Create a Poll */}
                <AccordionTab header="How to create a poll">
                    <video controls style={{ width: "100%" }}>
                        <source src="/user_manual/polls/creating_a_poll.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </AccordionTab>

                {/* Video 2: How to Respond to a Poll */}
                <AccordionTab header="How to respond to a poll through the CF app">
                    <video controls style={{ width: "100%" }}>
                        <source src="/user_manual/polls/cfp_app_respond_to_a_poll.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default Polls;
