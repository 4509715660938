import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "primereact/utils";

import setFieldTouched from "final-form-set-field-touched";

import { getAllGroups } from "../../../services/groups/groups-service";
import { getAllRegions } from "../../../services/locations/regions-service";
import { getAllDistricts } from "../../../services/locations/districts-service";
import { getAllCounty } from "../../../services/locations/county-service";
import { getAllSubcounty } from "../../../services/locations/subcounty-service";
import { getAllParish } from "../../../services/locations/parish-service";
import { getAllVillage } from "../../../services/locations/village-service";
//
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUpload } from "primereact/fileupload";

function RowForm({ handleSubmit, productCategoryBrandData, initialData, selectedPollId, ...props }) {
    console.log("🚀df ~ RowForm ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const [responseType, setResponseType] = useState(null);

    const [options, setOptions] = useState([]);

    const addOption = () => {
        setOptions([...options, ""]);
    };

    const setOptionto1 = () => {
        if (options.length === 0) {
            setOptions([""]);
        }
    };

    // const handleOptionChange = (value, index) => {
    //     const updatedOptions = [...options];
    //     updatedOptions[index] = value;
    //     setOptions(updatedOptions);
    // };

    const removeOption = (index) => {
        const updatedOptions = options.filter((_, i) => i !== index);
        setOptions(updatedOptions);
    };

    const removeAllOptions = (form, index) => {
        setOptions([]);
        const formState = form.getState();
        const { values } = formState;

        // Loop through the keys and remove all keys that start with 'options-'
        Object.keys(values).forEach((key) => {
            if (key.startsWith("options-")) {
                form.change(key, undefined); // Set the value to undefined or null to remove it
            }
        });
    };

    const validate = (values) => {
        const errors = {};

        if (!values.option) errors.option = "An Option is required";

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please first fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            console.log("choice submit: ", pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="option">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="option">Option</label>
                                        <InputText {...input} id="option" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-success" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    style={{ minWidth: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
