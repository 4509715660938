import React from "react";
import { Page, Text, View, Document, StyleSheet, Svg } from "@react-pdf/renderer";

// Define styles for the document
const styles = StyleSheet.create({
    page: {
        padding: 20,
        backgroundColor: "#FFFFFF",
    },
    section: {
        marginVertical: 10,
    },
    title: {
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 5,
        textAlign: "center", // Center the title
        textDecoration: "underline", // Underline the title
        color: "#23282D", // Set title color
    },
    header: {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center", // Center the header
    },
    subtext: {
        fontSize: 10,
        marginVertical: 2,
        color: "#808080",
    },
    text: {
        fontSize: 12,
        marginVertical: 2,
    },
    question: {
        marginTop: 10,
        fontWeight: "bold",
        color: "#4C00B0",
    },
    choice: {
        marginLeft: 20,
        fontSize: 11,
    },
    response: {
        marginLeft: 30,
        fontSize: 11,
    },
    statsContainer: {
        marginTop: 10,
        marginLeft: 20,
    },
    statRow: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: 2,
    },
    colorBox: {
        width: 12,
        height: 12,
        marginRight: 5,
    },
    statText: {
        fontSize: 10,
    },
});

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8", "#82ca9d"];

const MyDocument = ({ poll }) => {
    const processResponseStats = (question) => {
        if (!question.responses || !question.choices) return [];

        const responseCounts = {};
        const totalResponses = question.responses.length;

        question.choices.forEach((choice) => {
            responseCounts[choice.option] = 0;
        });

        question.responses.forEach((response) => {
            if (responseCounts.hasOwnProperty(response.response)) {
                responseCounts[response.response]++;
            }
        });

        return Object.entries(responseCounts).map(([option, count]) => ({
            option,
            count,
            percentage: totalResponses > 0 ? ((count / totalResponses) * 100).toFixed(1) : 0,
        }));
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.title}>{poll.title}</Text>
                    <Text style={styles.header}>{poll.description}</Text>
                </View>

                <Text style={styles.subtext}># Respondents: {poll.distinct_respondents_count}</Text>
                {poll.questions.map((question, qIndex) => (
                    <View key={question.id} style={styles.section}>
                        <Text style={styles.question}>
                            {qIndex + 1}. {question.question}
                        </Text>

                        {(question.response_type === "multi" || question.response_type === "single") && (
                            <View style={styles.statsContainer}>
                                {processResponseStats(question).map((stat, index) => (
                                    <View key={index} style={styles.statRow}>
                                        <View style={[styles.colorBox, { backgroundColor: COLORS[index % COLORS.length] }]} />
                                        <Text style={styles.statText}>
                                            {stat.option}: {stat.count} responses ({stat.percentage}%)
                                        </Text>
                                    </View>
                                ))}
                            </View>
                        )}

                        {question.responses && question.responses.length > 0 && question.response_type === "open" && (
                            <View>
                                {question.responses.map((response) => (
                                    <Text key={response.id} style={styles.response}>
                                        - {response.response} {/* Arrow instead of hyphen */}
                                    </Text>
                                ))}
                            </View>
                        )}
                    </View>
                ))}
            </Page>
        </Document>
    );
};

export default MyDocument;
