import axiosAPI from "../axiosApi";

export async function getAllQuestions(params = {}) {
    const response = await axiosAPI.get("questions", { params: params });
    return response;
}

export async function getQuestionById(id) {
    const response = await axiosAPI.get(`questions/` + id);
    return response;
}

export async function postQuestion(data) {
    const response = await axiosAPI.post(`questions`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateQuestion(id, data) {
    const response = await axiosAPI.post(`questions/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteQuestionById(id) {
    const response = await axiosAPI.delete(`questions/${id}`);
    return response;
}
