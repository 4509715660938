import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { getAllRegions, getRegionsById, postRegions, updateRegions, deleteRegionById } from "../../../services/locations/regions-service";
import { getAllCountrys } from "../../../services/locations/countries-service";
import { toast } from "react-toastify";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Dropdown } from "primereact/dropdown";

import useHandleQueryError from "../../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function CreateForm(props) {
    const queryClient = useQueryClient();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    // Fetch the list of countries
    const getListOfCountries = useQuery({ queryKey: ["countries"], queryFn: getAllCountrys });
    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfCountries?.isError, getListOfCountries?.error);

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postRegions,
        onSuccess: (data) => {
            queryClient.invalidateQueries(["regions"]);
            console.log("success creating districts is : ", data);
            toast.success("Region Created Successfully");
            setCreactMutationIsLoading(false);
            props.onClose();
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const validate = (values) => {
        const errors = {};

        if (!values.name) errors.name = "Name is required";
        if (!values.countryId) errors.countryId = "Country is required";

        return errors;
    };

    const onSubmitForm = (data) => {
        const payload = {
            ...data,
            country_id: data.countryId, // Extract only the id from the districtId field
        };

        setPendingData(payload);
        setShowConfirmDialog(true);
    };

    const onConfirm = () => {
        if (pendingData) {
            setCreactMutationIsLoading(true);
            creactMutation.mutate(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <Dialog header="Regions Form" visible={props.show} maximizable onHide={() => props.onHide()}>
            <div className="col-12 md:col-12">
                <div className="card p-fluid">
                    <Form
                        onSubmit={onSubmitForm}
                        validate={validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="name">Name</label>
                                            <InputText {...input} id="name" type="text" />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <Field name="countryId">
                                    {({ input, meta }) => (
                                        <div className="p-field m-4">
                                            <label htmlFor="countryId">Country</label>
                                            <Dropdown {...input} options={getListOfCountries?.data?.data?.data} optionLabel="name" optionValue="id" placeholder="Select a Country" />
                                            {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        </div>
                                    )}
                                </Field>

                                <div className="d-grid gap-2">
                                    <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                                </div>
                            </form>
                        )}
                    />
                    <Dialog
                        header="Confirmation"
                        visible={showConfirmDialog}
                        style={{ minWidth: "30vw" }}
                        maximizable
                        onHide={onCancel}
                        footer={
                            <div>
                                <Button label="Yes" onClick={onConfirm} />
                                <Button label="No" onClick={onCancel} className="p-button-secondary" />
                            </div>
                        }
                    >
                        Are you sure you want to submit?
                    </Dialog>
                    {creactMutationIsLoading && (
                        <div className="m-2">
                            <ProgressBar mode="indeterminate" />
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
}

export default CreateForm;
