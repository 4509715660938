import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import CitizenFeedback from "./CitizenFeedback";
import ServicesFeedback from "./ServicesFeedback";

import DashboardOverview from "./DashboardOverview";
import Polls from "./Polls";
import Notifications from "./Notifications";
import GroupManagement from "./GroupManagement";
import AppUserManual from "./AppUserManual";

const UserManualPage = () => {
    return (
        <div className="user-manual">
            <Accordion multiple>
                <AccordionTab header="Dashbaoard Overview">
                    <DashboardOverview />
                </AccordionTab>
                <AccordionTab header="Citizen Feedback">
                    <CitizenFeedback />
                </AccordionTab>
                <AccordionTab header="Services Feedback">
                    <ServicesFeedback />
                </AccordionTab>
                <AccordionTab header="Polls">
                    <Polls />
                </AccordionTab>
                <AccordionTab header="Notifications">
                    <Notifications />
                </AccordionTab>
                <AccordionTab header="Groups">
                    <GroupManagement />
                </AccordionTab>
                <AccordionTab header="CF APP">
                    <AppUserManual />
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default UserManualPage;
