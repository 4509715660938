import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import moment from "moment";
import "primeicons/primeicons.css"; // Ensure PrimeIcons are imported for use

function UserDetailsPage({ user, showModal, handleCloseModal }) {
    console.log("userDetail xxxxxx : ", user);
    return (
        <Dialog header="Bio Data" visible={showModal} onHide={handleCloseModal} maximizable modal>
            <div style={{ margin: "1rem" }}>
                {" "}
                <Panel header="Bio" toggleable>
                    <div className="p-grid" style={{ padding: "1rem" }}>
                        <div className="p-col-12 p-md-6">
                            <strong>Name:</strong> {user?.name}
                        </div>

                        <div className="p-col-12 p-md-6">
                            <strong>Email:</strong> {user?.email}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Phone:</strong> {user?.phone}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Gender:</strong> {user?.gender}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <strong>Registration Channel:</strong> {user?.registration_channel}
                        </div>

                        <div className="p-col-12 p-md-6">
                            <strong>Allow Notifications:</strong> {user?.allow_notifications ? "Yes" : "No"}
                        </div>

                        <div className="p-col-12 p-md-6">
                            <strong>Status:</strong> {user?.status}
                        </div>
                    </div>
                </Panel>
            </div>
            <div style={{ margin: "1rem" }}>
                {" "}
                <Panel header="Work Details" toggleable>
                    <div className="p-grid" style={{ padding: "1rem" }}>
                        <div className="p-col-12 p-md-6">
                            <strong>Role:</strong> {user?.role}
                        </div>
                        {["Directorate Officer"].includes(user?.role) && (
                            <>
                                <div className="p-col-12 p-md-6">
                                    <strong>Directorate:</strong> {user?.user_directorate?.directorate?.name}
                                </div>

                                <div className="p-col-12 p-md-6">
                                    <strong>Directorate Office:</strong> {user?.user_directorate_office?.directorate_office?.name}
                                </div>
                            </>
                        )}
                    </div>
                </Panel>
            </div>

            {["CSO Admin", "Group Reporter"].includes(user?.role) && (
                <div style={{ margin: "1rem" }}>
                    <Panel header="Group" toggleable>
                        <div className="p-grid" style={{ padding: "1rem" }}>
                            {/* Check and display user groups if the array exists and has elements */}
                            {Array.isArray(user?.user_groups) && user.user_groups.length > 0 ? (
                                user.user_groups.map((userGroup, index) => (
                                    <div key={index} className="p-col-12 p-md-6">
                                        <strong>Name:</strong> {userGroup?.group?.name ?? "N/A"}
                                        <br />
                                        <strong>Code:</strong> {userGroup?.group?.code ?? "N/A"}
                                    </div>
                                ))
                            ) : (
                                <div className="p-col-12">
                                    <strong>No user groups available</strong>
                                </div>
                            )}

                            {/* Check and display user group types if the array exists and has elements */}
                            {Array.isArray(user?.user_group_types) && user.user_group_types.length > 0 ? (
                                user.user_group_types.map((userGroupType, index) => (
                                    <div key={index} className="p-col-12 p-md-6">
                                        <strong>Type:</strong> {userGroupType?.group_type?.name ?? "N/A"}
                                    </div>
                                ))
                            ) : (
                                <div className="p-col-12">
                                    <strong>No user group types available</strong>
                                </div>
                            )}
                        </div>
                    </Panel>
                </div>
            )}
            {["Reporter", "Group Reporter"].includes(user?.role) && (
                <div style={{ margin: "1rem" }}>
                    <Panel header="Location" toggleable>
                        <div className="p-grid" style={{ padding: "1rem" }}>
                            <div className="p-col-12 p-md-6">
                                <strong>Citizenship:</strong> {user?.citizenship ?? "N/A"}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <strong>Region:</strong> {user?.region?.name ?? "N/A"}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <strong>District:</strong> {user?.district?.name ?? "N/A"}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <strong>County:</strong> {user?.county?.name ?? "N/A"}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <strong>Subcounty:</strong> {user?.subcounty?.name ?? "N/A"}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <strong>Parish:</strong> {user?.parish?.name ?? "N/A"}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <strong>Village:</strong> {user?.village?.name ?? "N/A"}
                            </div>
                        </div>
                    </Panel>
                </div>
            )}

            <div style={{ margin: "1rem" }}>
                <Panel header="Additional Information" toggleable>
                    <div className="p-grid" style={{ padding: "1rem" }}>
                        <div className="p-col-12 p-md-6">
                            <strong>Last Login:</strong> {moment(user?.lastlogin).format("MMMM Do, YYYY, h:mm:ss A")}
                        </div>

                        {/* Created By */}
                        <div className="p-col-12 p-md-6">
                            <strong>Created By:</strong> {user?.created_by?.name ?? "N/A"}
                        </div>

                        {/* Created By Email */}
                        <div className="p-col-12 p-md-6">
                            <strong>Created By Email:</strong> {user?.created_by?.email ?? "N/A"}
                        </div>

                        {/* Updated By */}
                        <div className="p-col-12 p-md-6">
                            <strong>Updated By:</strong> {user?.updated_by?.name ?? "N/A"}
                        </div>

                        {/* Created At */}
                        <div className="p-col-12 p-md-6">
                            <strong>Created At:</strong> {moment(user?.created_at).format("MMMM Do, YYYY, h:mm:ss A")}
                        </div>

                        {/* Updated At */}
                        <div className="p-col-12 p-md-6">
                            <strong>Updated At:</strong> {moment(user?.updated_at).format("MMMM Do, YYYY, h:mm:ss A")}
                        </div>
                    </div>
                </Panel>
            </div>

            {/* <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                <Button label="Close" icon="pi pi-times" onClick={handleCloseModal} className="p-button-outlined p-button-secondary" />
            </div> */}
        </Dialog>
    );
}

export default UserDetailsPage;
