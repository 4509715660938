import React, { useEffect, useState, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllQuestions, deleteQuestionById } from "../../services/questions/questions-service";
import { pollActivation } from "../../services/polls/polls-service";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../components/general_components/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import MyDocument from "./PrintPoll";

import ReactPDF from "@react-pdf/renderer";
import { BlobProvider } from "@react-pdf/renderer";

//
import useHandleQueryError from "../../hooks/useHandleQueryError";
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

import LoadingLottie from "../../assets/lotties/oag-lotties/loading-lottie.json";
import Lottie from "lottie-react";

function ListPage({ loggedInUserData, productCategoryBrandData, ...props }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedPoll, setSelectedPoll] = useState(location.state?.selectedPoll || null);
    const [activeLoading, setActiveLoading] = useState(false);

    useEffect(() => {
        if (location.state?.selectedPoll) {
            setSelectedPoll(location.state.selectedPoll); // Update local state with the passed poll
        }
    }, [location.state]);

    // const { selectedPoll } = location.state || {};
    console.log("🚀 ~ selectedPoll ~ data:", selectedPoll);

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["questions", selectedPoll.id],
        queryFn: () => getAllQuestions({ poll_id: selectedPoll.id }),
    });
    console.log("🚀 ~ Questions ListPage ~ data:", data?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => deleteQuestionById(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["questions"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const activatedPoll = useMutation({
        mutationFn: () => pollActivation(selectedPoll.id), // Ensure pollActivation is a function
        onSuccess: () => {
            // setIsLoadingExcelImportMutation(false);
            queryClient.invalidateQueries(["activepolls"]);
            // toast.current.show({
            //     severity: "success",
            //     summary: "Success",
            //     detail: "Poll activated successfully",
            //     life: 3000,
            // });
            // props.onClose();
        },
    });

    useHandleMutationError(activatedPoll?.error, setActiveLoading);

    const activatePoll = (active) => {
        confirmDialog({
            message: "Edit rights will be disabled after activation. Are you sure you want to Activate the Poll?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => activatePollConfirmed(active),
            reject: cancelDelete,
        });
    };

    const printPoll = () => {
        ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
    };

    const activatePollConfirmed = async (active) => {
        try {
            setActiveLoading(true);
            const poll_status = await activatedPoll.mutateAsync();
            console.log("poll_status", poll_status);
            const updatedPoll = { ...selectedPoll, status: active };
            // Navigate back to the responses page with the updated question
            queryClient.invalidateQueries(["questions"]);
            setActiveLoading(false);
            navigate("/polls/questions", { state: { selectedPoll: updatedPoll }, replace: true });
            // window.location.reload();
            // const timer = setTimeout(() => {}, 1000);
        } catch (error) {
            console.error("Activation failed:", error);
        }
    };

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    let tableId = 0;
    console.log("status check1", selectedPoll);
    const columns = useMemo(
        () => [
            {
                title: "Question",
                field: "question",
                render: (rowData) => {
                    console.log("status check2", selectedPoll); // Should log the updated selectedPoll
                    return (
                        <Link
                            to="responses"
                            state={{ selectedQuestion: rowData, selectedPoll: selectedPoll }} // Pass the updated selectedPoll
                            className="text-decoration-none"
                        >
                            {rowData.question}
                        </Link>
                    );
                },
            },
            {
                title: "Response Type",
                field: "response_type",
                render: (rowData) => {
                    if (rowData.response_type === "single") {
                        return "Single Choice";
                    }
                    if (rowData.response_type === "multi") {
                        return "Multiple Choice";
                    }
                    if (rowData.response_type === "open") {
                        return "Open Ended";
                    }
                },
            },
            {
                title: "Date",
                field: "created_at",
                hidden: true,
                render: (rowData) => {
                    return moment(rowData?.created_at).format("lll");
                },
            },
        ],
        [selectedPoll] // Ensure columns re-evaluates when selectedPoll changes
    );

    console.log("status check columns", columns);

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header={selectedPoll.title} style={{ marginBottom: "20px" }} toggleable>
                <div style={{ margin: "1rem", gap: "1rem" }}>
                    <h5>Description: {selectedPoll.description}</h5>
                </div>
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                    {selectedPoll.status !== "active" && <Button label="Activate Poll" disabled={(data?.data?.data ?? []).length <= 0} className="p-button-warning" onClick={() => activatePoll("active")} />}
                    {selectedPoll.status !== "active" && activeLoading && <Lottie animationData={LoadingLottie} style={{ height: "50px" }} loop={true} autoplay={true} />}
                    {selectedPoll.status === "active" && <Button label="Activated" disabled={true} className="p-button-success" onClick={() => activatePoll("active")} />}
                    {/* {selectedPoll.status === "active" && <Button label="Print Poll" disabled={false} className="p-button-warning" onClick={printPoll} />} */}
                    {activeUser?.permissions.includes("create") && selectedPoll.status !== "active" && <Button label="Add Question" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    {/* const PDFDownloadComponent = () => ( */}
                    {selectedPoll.status === "active" && (
                        <BlobProvider document={<MyDocument poll={selectedPoll} />}>
                            {({ url }) => (
                                <a href={url} download={`${selectedPoll.title}.pdf`}>
                                    <Button label="Download Poll" disabled={false} className="p-button-warning" />
                                </a>
                            )}
                        </BlobProvider>
                    )}
                    {/* ); */}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} selectedPollId={selectedPoll.id} productCategoryBrandData={productCategoryBrandData} />
                </div>

                <MuiTable
                    // sx={{
                    //     "& .MuiTableCell-root": {
                    //         padding: "56px", // Adjust this value as needed
                    //     },
                    // }}
                    showViewPage={true}
                    handleViewPage={(rowData) => {
                        navigate("responses", { state: { selectedQuestion: rowData, selectedPoll: selectedPoll } });
                    }}
                    key={selectedPoll.status}
                    tableTitle="Questions"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    // handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    // showEdit={activeUser?.permissions.includes("update")}
                    showDelete={selectedPoll.status !== "active" && activeUser?.permissions.includes("delete")}
                    loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    // //
                    // handleViewPage={(rowData) => {
                    //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //
                    exportButton={true}
                    pdfExportTitle="Questions"
                    csvExportTitle="Questions"
                />

                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
            </Panel>
        </div>
    );
}

export default ListPage;
