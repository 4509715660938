import React, { useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import { Grid, FormControlLabel, Checkbox, Typography, Button, Tabs, Tab, Box } from "@mui/material";

import { getAllRolesAndModifiedPermissionsService, syncPermissionToRoleService } from "../../../../services/roles/roles-service";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { Select, MenuItem, DialogContentText } from "@mui/material";

//
import useHandleMutationError from "../../../../hooks/useHandleMutationError.js";

const RowForm = ({ rolesAndModifiedPermissionData }) => {
    const initialData = [
        {
            role: "Admin",
            permissions: [
                { name: "add", value: true },
                { name: "edit", value: false },
                { name: "delete", value: true },
            ],
        },
        {
            role: "User",
            permissions: [
                { name: "add", value: false },
                { name: "edit", value: true },
                { name: "delete", value: false },
            ],
        },
    ];

    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const [createMutationIsLoading, setCreateMutationIsLoading] = useState(false);

    const queryClient = useQueryClient();

    const creactMutation = useMutation({
        mutationFn: syncPermissionToRoleService,
        onSuccess: () => {
            queryClient.invalidateQueries(["roles-with-modified-permissions"]);
            toast.success("Roles and Permissions Synced Successfully");
            setCreateMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreateMutationIsLoading);

    const handleSubmit = async (data) => {
        console.log("🚀 ~ handleSubmit ~ data:", data);
        setCreateMutationIsLoading(true);
        // event.preventDefault();
        console.log("data we are submitting while creating a hospital : ", data);
        // creactMutation.mutate(data);
    };

    // =========================== confirm submit =========================
    const [openConfirmDiaglog, setOpenConfirmDiaglog] = useState(false);
    const [formValues, setFormValues] = useState(null);

    const handleConfirmOpen = (values) => {
        console.log("🚀 ~ handleConfirmOpen ~ values:", values);

        setFormValues(values);
        setOpenConfirmDiaglog(true);
    };

    const handleConfirmClose = () => {
        setOpenConfirmDiaglog(false);
        setCreateMutationIsLoading(false);
    };

    const handleConfirmSubmit = (event) => {
        event.preventDefault();
        console.log("🚀 ~ handleConfirmSubmit ~ formValues:", formValues);
        setCreateMutationIsLoading(true);

        creactMutation.mutate({ roles: formValues });

        setOpenConfirmDiaglog(false);
        // setIsSubmittingFormData(false);
    };

    //========================================= confirm submit end ======================

    return (
        <>
            <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                {rolesAndModifiedPermissionData.map((role, index) => (
                    <Tab key={index} label={role.role} />
                ))}
            </Tabs>

            {rolesAndModifiedPermissionData.map((role, index) => (
                <TabPanel key={index} value={selectedTab} index={index}>
                    <Formik
                        initialValues={{ permissions: role.permissions }}
                        onSubmit={(values) => {
                            const formattedData = [
                                {
                                    role: role.role, // Include the role name
                                    permissions: values.permissions, // Include permissions array
                                },
                            ];
                            console.log(formattedData); // This will log the data in the required format
                            handleConfirmOpen(formattedData);
                        }}
                    >
                        {({ values, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <FieldArray name="permissions">
                                        {() => (
                                            <>
                                                {values.permissions.map((permission, idx) => (
                                                    <Grid item xs={6} key={idx}>
                                                        <FormControlLabel control={<Checkbox checked={permission.value} onChange={(e) => setFieldValue(`permissions.${idx}.value`, e.target.checked)} />} label={permission.name} />
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </FieldArray>
                                    <Grid item xs={12}>
                                        <Button disableElevation disabled={createMutationIsLoading} fullWidth size="large" type="submit" variant="contained" color="primary">
                                            {createMutationIsLoading ? <CircularProgress size={24} /> : "Submit"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </TabPanel>
            ))}

            <Dialog open={openConfirmDiaglog} onClose={handleConfirmClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Confirm Submission"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to submit ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmSubmit} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

export default RowForm;
