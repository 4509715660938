import axiosAPI from "../axiosApi";

export async function getAllGroups(params = {}) {
    const response = await axiosAPI.get("groups", { params: params });
    return response;
}

export async function getGroupById(id) {
    const response = await axiosAPI.get(`groups/` + id);
    return response;
}

export async function postGroup(data) {
    const response = await axiosAPI.post(`groups`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateGroup(id, data) {
    const response = await axiosAPI.post(`groups/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteGroupById(id) {
    const response = await axiosAPI.delete(`groups/${id}`);
    return response;
}
