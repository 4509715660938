import axiosAPI from "../axiosApi";

export async function getAllRegions(params = {}) {
    const response = await axiosAPI.get("regions", { params: params });
    return response;
}

export async function getRegionsById(id) {
    const response = await axiosAPI.get(`regions/` + id);
    return response;
}

export async function postRegions(data) {
    const response = await axiosAPI.post(`regions`, data);
    return response;
}

export async function updateRegions(id, data) {
    const response = await axiosAPI.put(`regions/${id}`, data);
    return response;
}

export async function deleteRegionById(id) {
    const response = await axiosAPI.delete(`regions/${id}`);
    return response;
}
