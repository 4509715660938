import React from "react";
import DistrictsList from "./DistrictsList";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../components/general_components/BreadcrumbNav";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useLocation } from "react-router-dom";

const createBreadCrump = () => {};
//
function DistrictsPage() {
    let { state } = useLocation();
    let selectedRegion = state ? state.selectedRegion : null;
    return (
        <div>
            <BreadcrumbNav />

            <DistrictsList selectedRegion={selectedRegion} />
        </div>
    );
}

export default DistrictsPage;
