import React from "react";

import { Grid, Stack } from "@mui/material";
import { Panel } from "primereact/panel";

//
import AllFeedbackStatisticsPage from "./AllFeedbackStatisticsPage";
import AllNewFeedbackStatisticsPage from "./AllNewFeedbackStatisticsPage";
import NonAuditFeedbackStatisticsPage from "./NonAuditFeedbackStatisticsPage";
import AuditableFeedbackStatisticsPage from "./AuditableFeedbackStatisticsPage";
import TaggedFeedbackStatisticsPage from "./TaggedFeedbackStatisticsPage";

import SummarisedIssuesStatisticsPage from "./SummarisedIssuesStatisticsPage";
import AssignedToMeSummarisedFeedbackStatisticsPage from "./AssignedToMeSummarisedFeedbackStatisticsPage";
import RejectedSummarisedFeedbackStatisticsPage from "./RejectedSummarisedFeedbackStatisticsPage";
import InProgressSummarisedFeedbackStatisticsPage from "./InProgressSummarisedFeedbackStatisticsPage";
import DoneSummarisedFeedbackStatisticsPage from "./DoneSummarisedFeedbackStatisticsPage";
import FailedSummarisedFeedbackStatisticsPage from "./FailedSummarisedFeedbackStatisticsPage";
import CompletedSummarisedFeedbackStatisticsPage from "./CompletedSummarisedFeedbackStatisticsPage";

//
import useAuthContext from "../../../context/AuthContext";

function CardsPage() {
    const { getUserQuery } = useAuthContext();
    const loggedInUserData = getUserQuery?.data?.data;
    return (
        <Panel header={`Statistics`} toggleable style={{ minWidth: "100%" }}>
            <Grid style={{ padding: "10px" }} container spacing={2} justifyContent="center" alignItems="center">
                {/* <AllFeedbackStatisticsPage /> */}
                <AllNewFeedbackStatisticsPage />
                <NonAuditFeedbackStatisticsPage />
                <AuditableFeedbackStatisticsPage />
                <TaggedFeedbackStatisticsPage />

                {!loggedInUserData?.role.includes("CSO") && (
                    <>
                        <SummarisedIssuesStatisticsPage />
                        <AssignedToMeSummarisedFeedbackStatisticsPage />
                        <RejectedSummarisedFeedbackStatisticsPage />
                        <InProgressSummarisedFeedbackStatisticsPage />
                        <DoneSummarisedFeedbackStatisticsPage />
                        <CompletedSummarisedFeedbackStatisticsPage />
                        <FailedSummarisedFeedbackStatisticsPage />
                    </>
                )}
            </Grid>
        </Panel>
    );
}

export default CardsPage;
