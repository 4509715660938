import React from "react";
import RegionList from "./RegionList";
import { Link } from "react-router-dom";

import BreadcrumbNav from "../../../components/general_components/BreadcrumbNav";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const createBreadCrump = () => {};
//
function RegionPage() {
    return (
        <div>
            <BreadcrumbNav />

            <RegionList />
        </div>
    );
}

export default RegionPage;
