import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { syncLdapUsers } from "../../services/auth/user-service"; // Assuming you have a service to call the API
import { useMutation, useQueryClient } from "@tanstack/react-query";

//
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

function SyncLdapUsersButton() {
    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false); // State for custom confirmation dialog

    const syncUsersMutation = useMutation({
        mutationFn: syncLdapUsers,
        onSuccess: () => {
            queryClient.invalidateQueries(["users"]);
            toast.success("Users synced successfully");
            setIsLoading(false);
        },
        // onError: (error) => {
        //     toast.error("Failed to sync users: " + error.message);
        //     setIsLoading(false);
        // },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(syncUsersMutation?.error, setIsLoading);

    const handleSyncUsers = () => {
        setIsLoading(true);
        syncUsersMutation.mutate();
        setShowConfirmDialog(false); // Close dialog after confirming
    };

    const confirmSync = () => {
        setShowConfirmDialog(true);
    };

    const onCancelSubmit = () => {
        setShowConfirmDialog(false);
        toast.info("Sync operation cancelled");
    };

    return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
            {/* Custom Confirmation Dialog */}
            <Dialog
                header="Confirm Submission"
                visible={showConfirmDialog}
                maximizable
                onHide={onCancelSubmit}
                footer={
                    <div>
                        <Button label="Yes" onClick={handleSyncUsers} />
                        <Button label="No" onClick={onCancelSubmit} className="p-button-secondary" />
                    </div>
                }
            >
                Are you sure you want to sync LDAP users?
            </Dialog>

            {/* Sync Users Button */}
            <Button label="Sync Users" icon="pi pi-refresh" onClick={confirmSync} disabled={isLoading} />

            {/* Loading Spinner */}
            {isLoading && (
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <ProgressSpinner style={{ width: "30px", height: "30px" }} strokeWidth="8" animationDuration=".5s" />
                </div>
            )}
        </div>
    );
}

export default SyncLdapUsersButton;
