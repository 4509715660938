import axiosAPI from "../axiosApi";

export async function getAllUsers(params = {}) {
    const response = await axiosAPI.get("users", { params: params });
    return response;
}

export async function getUserById(id) {
    const response = await axiosAPI.get(`users/${id}`);
    return response;
}

export async function getApproverRoles() {
    const response = await axiosAPI.get(`approver-roles`);
    return response;
}

export async function createUser(data) {
    const response = await axiosAPI.post(`users`, data);
    return response;
}

export async function updateUser(id, data) {
    const response = await axiosAPI.put(`users/${id}`, data);
    return response;
}

export async function userProfileUpdate(id, data) {
    const response = await axiosAPI.patch(`userProfileUpdate/${id}`, data);
    return response;
}

export async function deleteUserById(id) {
    console.log("deleting user endpoint");
    const response = await axiosAPI.delete(`users/${id}`);
    console.log("deleting user response is : ", response);
    return response;
}

export async function getAssignableRoles(params = {}) {
    const response = await axiosAPI.get("roles", { params: params });
    return response;
}

export const syncLdapUsers = async () => {
    const response = await axiosAPI.post("import-and-sync-ldap-users");
    return response.data;
};

export async function bulkSendNotificationToUsers(data) {
    const response = await axiosAPI.post(`bulkSendNotificationToUsers`, data);
    return response;
}

export async function bulkDestroyUsers(data) {
    const response = await axiosAPI.post(`bulkDestroyUsers`, data);
    return response;
}
