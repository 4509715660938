import React, { useState } from "react";
import SummarisedFeedbackBySectorBarChart from "./SummarisedFeedbackBySectorBarChart";
import FeedbackBySectorBarChart from "./FeedbackBySectorBarChart"; // Assuming this component exists
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown"; // PrimeReact Dropdown component
//
import useAuthContext from "../../../context/AuthContext";

function BarChartsPage() {
    const { getUserQuery } = useAuthContext();
    const loggedInUserData = getUserQuery?.data?.data;

    const [selectedChart, setSelectedChart] = useState("feedback-by-sector");

    const chartOptions = [
        { label: "Feedback by Sector", value: "feedback-by-sector" },
        { label: "Summarised Feedback by Sector", value: "summarised-feedback-by-sector" },
    ];

    const csoChartOptions = [{ label: "Feedback by Sector", value: "feedback-by-sector" }];

    const handleChartChange = (e) => {
        setSelectedChart(e.value);
    };

    return (
        <div className="col-12">
            <Panel header={`Bar Charts`} toggleable style={{ minWidth: "100%" }}>
                <div className="col-12" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", padding: "10px" }}>
                    <h4>{selectedChart === "feedback-by-sector" ? "Feedback by Sector" : "Summarised Feedback by Sector"}</h4>
                    <Dropdown
                        value={selectedChart}
                        options={["CSO Admin"].includes(loggedInUserData?.role) ? csoChartOptions : chartOptions}
                        onChange={handleChartChange}
                        placeholder="Select Chart"
                        style={{ width: "200px" }} // Right-align the dropdown
                    />
                </div>

                <div className="col-12" style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-12">{selectedChart === "feedback-by-sector" ? <FeedbackBySectorBarChart /> : <SummarisedFeedbackBySectorBarChart />}</div>
                </div>
            </Panel>
        </div>
    );
}

export default BarChartsPage;
