import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const useHandleMutationError = (error, setIsLoading) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();

    // Memoize the error object
    const memoizedError = useMemo(() => error, [error]);

    // Log the error and memoizedError inside useEffect to confirm it triggers
    console.log("🚀 ~ useHandleMutationError ~ error:", error);

    useEffect(() => {
        if (memoizedError) {
            setIsLoading(false);
            const errorMessage = memoizedError?.response?.data?.message;

            if (errorMessage) {
                if (errorMessage === "Unauthenticated.") {
                    toast.warning("Session expired. Please log in again.");
                    if (!["/login", "/sign-up", "/reset-password"].includes(location.pathname)) {
                        queryClient.resetQueries({ queryKey: ["logged-in-user"] });
                        queryClient.removeQueries({ queryKey: ["logged-in-user"] });
                        queryClient.clear();
                        navigate("/login");
                    }
                } else {
                    toast.error(errorMessage);
                }
            } else if (!memoizedError?.response) {
                toast.warning("Check your internet connection, please.");
            } else {
                toast.error("An error occurred. Please contact admin.");
            }
        }
    }, [memoizedError]);
};

export default useHandleMutationError;
