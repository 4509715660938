import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import setFieldTouched from "final-form-set-field-touched";
import { InputSwitch } from "primereact/inputswitch";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Prefer not to say", value: "prefer not to say" },
];

function RowFormForUserProfileUpdate({ handleSubmit, initialData }) {
    console.log("🚀 ~ RowFormForUserProfileUpdate ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = "Name is required";
        if (!values.email) errors.email = "Email is required";
        if (!values.phone) errors.phone = "Phone number is required";
        if (!values.current_password) {
            errors.current_password = "Current password is required";
        }
        if (values.updatePassword) {
            if (!values.password) errors.password = "Password is required";
            if (values.password !== values.confirm_password) errors.confirm_password = "Passwords do not match";
        }

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                onSubmitForm(values, form);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputTextarea {...input} id="name" rows={1} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="email">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="email">Email</label>
                                        <InputTextarea {...input} id="email" rows={1} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="phone">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="phone">Phone</label>
                                        <InputTextarea {...input} id="phone" rows={1} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="allow_notifications" type="checkbox">
                                {({ input, meta }) => (
                                    <div className="p-field-checkbox m-4">
                                        {/* <Checkbox inputId="allow_notifications" {...input} checked={!!input.value} /> */}
                                        <InputSwitch
                                            {...input}
                                            checked={!!input.checked ? true : false}
                                            id="allow_notifications"
                                            onChange={(e) => {
                                                console.log("🚀 ~ RowForm ~ e:", e);
                                                input.onChange(e.value);
                                            }}
                                        />
                                        <label htmlFor="allow_notifications">Allow Notifications</label>
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="gender">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="gender">Gender</label>
                                        <Dropdown {...input} id="gender" options={genderOptions} placeholder="Select Gender" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="current_password">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="current_password">Current Password</label>
                                        <Password {...input} id="current_password" toggleMask className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* Update Password Toggle */}
                            <Field name="updatePassword" type="checkbox">
                                {({ input }) => (
                                    <div className="p-field-checkbox m-4">
                                        <InputSwitch
                                            {...input}
                                            checked={!!input.checked ? true : false}
                                            onChange={(e) => {
                                                input.onChange(e.value);
                                                // Clear the password fields if `updatePassword` is unchecked
                                                if (!e.value) {
                                                    form.change("password", null);
                                                    form.change("confirm_password", null);
                                                }
                                            }}
                                        />
                                        <label htmlFor="updatePassword">Update Password</label>
                                    </div>
                                )}
                            </Field>

                            {/* Conditional Password Fields */}
                            {values.updatePassword && (
                                <>
                                    <Field name="password">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="password">New Password</label>
                                                <Password {...input} id="password" toggleMask className={classNames({ "p-invalid": meta.touched && meta.error })} feedback />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="confirm_password">
                                        {({ input, meta }) => (
                                            <div className="p-field m-4">
                                                <label htmlFor="confirm_password">Confirm New Password</label>
                                                <Password {...input} id="confirm_password" toggleMask className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                                {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                            </div>
                                        )}
                                    </Field>
                                </>
                            )}

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    style={{ minWidth: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowFormForUserProfileUpdate;
