import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";

const AppUserManual = () => {
    const filePath = "/user_manual/cf_app/oag_cfp_app_user_manual.pdf";
    const fileName = "Download CF App User Manual";

    return (
        <div className="user-manual">
            <h2>CF App User Manual</h2>
            <p>This section provides the CF app user manual. You can view the PDF below to learn how to use the features within the application.</p>
            <Accordion multiple>
                {/* PDF for User Manual */}
                <AccordionTab header="View User Manual">
                    <iframe src={filePath} title="CF App User Manual" style={{ width: "100%", height: "600px" }} />
                    <div style={{ marginTop: "1rem", textAlign: "center" }}>
                        <a href={`${filePath}`} target="_blank" rel="noopener noreferrer" download>
                            <Button label={fileName} icon="pi pi-file-pdf" className="p-button-raised p-button-primary" />
                        </a>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default AppUserManual;
