import axiosAPI from "../axiosApi";

export async function getAllSubcounty(params = {}) {
    const response = await axiosAPI.get("subcounties", { params: params });
    return response;
}

export async function getSubcountyById(id) {
    const response = await axiosAPI.get(`subcounties` + id);
    return response;
}

export async function postSubcounty(data) {
    const response = await axiosAPI.post(`subcounties`, data);
    return response;
}

export async function updateSubcounty(id, data) {
    const response = await axiosAPI.put(`subcounties/${id}`, data);
    return response;
}

export async function deleteSubcountyById(id) {
    const response = await axiosAPI.delete(`subcounties/${id}`);
    return response;
}
