import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import EditQuestion from "./EditQuestion";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";
import ViewForm from "./ViewForm";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllChoices, deleteChoiceById } from "../../services/choices/choices-service";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../components/general_components/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { useLocation } from "react-router-dom";

//
import useHandleQueryError from "../../hooks/useHandleQueryError";
import useHandleMutationError from "../../hooks/useHandleMutationError.js";
function ChoiceListPage({ loggedInUserData, productCategoryBrandData, ...props }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedPoll, setSelectedPoll] = useState(location.state?.selectedPoll || null);
    const [selectedQuestion, setSelectedQuestion] = useState(location.state?.selectedQuestion || null);

    useEffect(() => {
        if (location.state?.selectedPoll) {
            setSelectedPoll(location.state.selectedPoll); // Update local state with the passed poll
            setSelectedQuestion(location.state.selectedQuestion); // Update local state with the passed poll
        }
    }, [location.state]);

    // const { selectedPoll } = location.state || {};
    // console.log("🚀 ~ selectedPoll ~ data:", selectedPoll);

    // const { selectedQuestion, selectedPoll } = location.state || {};
    console.log("🚀 ~ selectedQuestion ~ data z:", selectedQuestion);
    console.log("🚀 ~ selectedPoll ~ data: choices:", selectedPoll);
    // const [question, setQuestion] = useState("");

    // useEffect(() => {
    //     if (location.state?.selectedQuestion) {
    //         setQuestion(location.state.selectedQuestion.question);
    //     }
    // }, [location.state]);

    const response_type = selectedQuestion.response_type === "open" ? "Open Ended" : selectedQuestion.response_type === "single" ? "Single Choice" : "Multiple Choice";

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["choices", selectedQuestion.id],
        queryFn: () => getAllChoices({ question_id: selectedQuestion.id }),
    });
    console.log("🚀 ~ choices ListPage ~ data:", data?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => deleteChoiceById(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["questions"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm2, setShowAddForm2] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showViewForm, setShowViewForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };

    const handleShowViewForm = (item) => {
        setSelectedItem(item);
        setShowViewForm(true);
        console.log("handleShowViewForm is : ", item);
    };

    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const handleCloseViewForm = () => {
        setSelectedItem({ id: null });
        setShowViewForm(false);
    };

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    const onFormClose2 = () => {
        setShowAddForm2(false);
    };

    let tableId = 0;
    const columns = [
        // {
        //     title: "#",
        //     width: "5%",
        //     render: (rowData) => {
        //         return data?.data?.data.id;
        //         // return data?.data?.data.indexOf(rowData);
        //     },
        // },
        {
            title: "Choice",
            field: "option",
        },
        // {
        //     title: "Response Type",
        //     field: "response_type",
        //     render: (rowData) => {
        //         if (rowData.response_type === "single") {
        //             return "Single Choice";
        //         }
        //         if (rowData.response_type === "multi") {
        //             return "Multiple Choice";
        //         }
        //         if (rowData.response_type === "open") {
        //             return "Open Ended";
        //         }
        //     },
        // },
        {
            title: "Date",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
    ];

    // const listTemplate = (choice) => {
    //     return (
    //         <div style={{ padding: "20px", margin: "5px", borderBottom: "1px solid #eee", width: "100%", backgroundColor: "#F1F2F3", display: "flex", justifyContent: "space-between" }}>
    //             {choice.option}
    //             <span>
    //                 <Button
    //                     icon="pi pi-pencil"
    //                     label="Edit"
    //                     style={{ height: "20px", backgroundColor: "transparent", color: "green", fontSize: "12px", border: "none" }} // Adjust the size as needed
    //                     onClick={() => setShowAddForm(true)}
    //                 />
    //                 <Button
    //                     icon="pi pi-pencil"
    //                     label="Edit"
    //                     style={{ height: "20px", backgroundColor: "transparent", color: "green", fontSize: "12px", border: "none" }} // Adjust the size as needed
    //                     onClick={() => setShowAddForm(true)}
    //                 />
    //             </span>
    //         </div>
    //     );
    // };

    const handleOnClose = (question) => {
        // Here you would typically update the question in your data store
        // For this example, we'll just simulate an update
        const updatedQuestion = { ...selectedQuestion, question: question };
        setShowAddForm(false);

        // Navigate back to the responses page with the updated question
        navigate("/polls/questions/responses", { state: { selectedQuestion: updatedQuestion, selectedPoll: selectedPoll }, replace: true });
    };

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header={`${response_type} Question`} style={{ marginBottom: "20px" }} toggleable>
                <div style={{ margin: "1rem", gap: "1rem" }}>
                    <h5>
                        {selectedQuestion.question} {"  "}
                        {activeUser?.permissions.includes("create") && selectedPoll.status !== "active" && (
                            <Button
                                icon="pi pi-pencil"
                                label="Edit"
                                style={{ height: "20px", backgroundColor: "transparent", color: "green", fontSize: "12px", border: "none" }} // Adjust the size as needed
                                onClick={() => setShowAddForm(true)}
                            />
                        )}
                    </h5>
                    <EditQuestion show={showAddForm} onHide={() => setShowAddForm(false)} handleOnClose={handleOnClose} onClose={onFormClose} selectedQuestion={selectedQuestion} selectedPoll={selectedPoll} productCategoryBrandData={productCategoryBrandData} />
                    {/* <EidtQuestion rowData={selectedQuestion} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} /> */}
                </div>
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {activeUser?.permissions.includes("create") && selectedPoll.status !== "active" && <Button label="Add Choice" className="p-button-primary" onClick={() => setShowAddForm2(true)} />}
                    <CreateForm show={showAddForm2} selectedQuestion={selectedQuestion} onHide={() => setShowAddForm2(false)} onClose={onFormClose2} productCategoryBrandData={productCategoryBrandData} />
                </div>

                {/* {selectedQuestion.response_type !== "open" && (
                    <div>
                        <h5 style={{ marginLeft: "12px" }}>Choices</h5>
                        <DataView value={data?.data?.data} layout="list" itemTemplate={listTemplate} paginator rows={7} />
                    </div>
                )} */}

                {selectedQuestion.response_type !== "open" && (
                    <MuiTable
                        tableTitle="Choices"
                        tableData={data?.data?.data ?? []}
                        tableColumns={columns}
                        handleShowEditForm={handleShowEditForm}
                        handleDelete={(e, item_id) => handleDelete(e, item_id)}
                        showEdit={activeUser?.permissions.includes("update") && selectedPoll.status !== "active"}
                        showDelete={activeUser?.permissions.includes("delete") && selectedPoll.status !== "active"}
                        loading={isLoading || status === "loading" || deleteMutationIsLoading}
                        // //
                        // handleViewPage={(rowData) => {
                        //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                        // }}
                        // showViewPage={true}
                        // hideRowViewPage={false}
                        //
                        showViewPage={true}
                        handleViewPage={handleShowViewForm}
                        exportButton={true}
                        pdfExportTitle="Question"
                        csvExportTitle="Question"
                        style={{ height: data?.data?.data.length ? "auto" : "100px" }}
                    />
                )}

                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                {selectedItem && <ViewForm rowData={selectedItem} show={showViewForm} onHide={handleCloseViewForm} onClose={handleCloseViewForm} />}
            </Panel>
        </div>
    );
}

export default ChoiceListPage;
