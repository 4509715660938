import axiosAPI from "../axiosApi";

export async function getAllPolls(params = {}) {
    const response = await axiosAPI.get("polls", { params: params });
    return response;
}

export async function getPollById(id) {
    const response = await axiosAPI.get(`polls/` + id);
    return response;
}

export async function pollActivation(id) {
    const response = await axiosAPI.get(`activate-poll/` + id);
    return response;
}

export async function postPoll(data) {
    const response = await axiosAPI.post(`polls`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updatePoll(id, data) {
    const response = await axiosAPI.post(`polls/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deletePollById(id) {
    const response = await axiosAPI.delete(`polls/${id}`);
    return response;
}
