import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "../summarised-feedback/CreateForm";

import EditForm from "../summarised-feedback/EditForm";
import CommentsForm from "./CommentsForm";
import SummarisedFeedbackWithNotEditableCommentsDetailsModal from "../../global_views/SummarisedFeedbackWithNotEditableCommentsDetailsModal";
import TakeActionForm from "./TakeActionForm";
import ApprovalForm from "./ApprovalForm";

import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllSummarisedFeedbacks, getSummarisedFeedbackById, postSummarisedFeedback, postToBulkDestroySummarisedFeedback, updateSummarisedFeedback, deleteSummarisedFeedbackById } from "../../../services/feedback/summarised-feedback-service";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../../components/general_components/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import Tooltip from "@mui/material/Tooltip";

import { Tag } from "primereact/tag";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";
function ListPage({ loggedInUserData, productCategoryBrandData, ...props }) {
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["summarised_feedback", "AAGC"],
        queryFn: () => getAllSummarisedFeedbacks({ spatie_current_statuses: ["AAGC"] }),
    });
    console.log("🚀 ~Corporate Management Summarised Feedbacks ListPage ~ data: ", data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => postToBulkDestroySummarisedFeedback(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["summarised_feedback"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (e, data) => {
        console.log("🚀 ~ handleDelete ~ data:", data);
        // let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(data),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteData) => {
        if (selectedDeleteData !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate({ feedbacks: selectedDeleteData });
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    const getSummarisedFeedbackSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "Corporate Management":
                return "primary"; // Dark Blue or any strong color to represent high-level management
            case "AAGC":
                return "warning"; // Yellow/Orange (indicating it's under AAGC review)
            case "Accepted by AAGC":
                return "success"; // Green (indicating approval or completion by AAGC)
            case "Rejected by AAGC":
                return "danger"; // Red (indicating rejection or failure by AAGC)
            case "Directorate Officer":
                return "info"; // Blue (indicating it's being handled by a Directorate Officer)
            case "in progress":
                return "warning"; // Yellow/Orange (indicating it's still in progress)
            case "done":
                return "success"; // Green (indicating completion)
            case "failed":
                return "danger"; // Red (indicating failure)
            case "not satisfied":
                return "danger"; // Red (indicating the task is completed but with an unsatisfactory outcome)
            case "completed":
                return "success"; // Green (indicating completion)
            default:
                return "secondary"; // Gray or any other default color for unknown statuses
        }
    };
    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },

        {
            title: "Feedback No",
            field: "feedback_no",
            render: (rowData) => (
                <Tag
                    value={rowData?.feedback_no ? rowData?.feedback_no : "N/A"}
                    severity={getSummarisedFeedbackSeverityColor(rowData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                />
            ),
        },

        {
            title: "Title",
            field: "title",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const title = rowData?.title || "No title";
                return title.length > maxLength
                    ? `${title.substring(0, maxLength)}...` // Truncate and add ellipses
                    : title; // Display full title if it's short enough
            },
        },
        {
            title: "Message",
            field: "message",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const message = rowData?.message || "No message";
                return message.length > maxLength
                    ? `${message.substring(0, maxLength)}...` // Truncate and add ellipses
                    : message; // Display full message if it's short enough
            },
        },

        { title: "Sector", field: "sector.name", hidden: true },
        {
            title: "Has Attachments",
            field: "has_attachments",
            render: (rowData) => {
                return rowData?.has_attachments ? "Yes" : "No";
            },
            hidden: true,
        },
        {
            title: "Has Attachments",
            field: "has_attachments",
            render: (rowData) => {
                return rowData?.has_attachments ? "Yes" : "No";
            },
            hidden: true,
        },
        { title: "Region", field: "region.name", hidden: true },
        { title: "District", field: "district.name", hidden: true },
        { title: "County", field: "county.name", hidden: true },
        { title: "Subcounty", field: "subcounty.name", hidden: true },
        { title: "Parish", field: "parish.name", hidden: true },
        { title: "Village", field: "village.name", hidden: true },
        { title: "Latitude", field: "latitude", hidden: true },
        { title: "Longitude", field: "longitude", hidden: true },
        { title: "Location Scope", field: "location_scope", hidden: true },
        { title: "Country", field: "country.name", hidden: true },
        { title: "Current Status", field: "spatie_current_status.name", hidden: true },
        { title: "Current Status Reason", field: "spatie_current_status.reason", hidden: true },
        { title: "Created By", field: "created_by.name", hidden: true },
        { title: "Created By Email", field: "created_by.email", hidden: true },
        { title: "Updated By", field: "updated_by.name", hidden: true },
        {
            title: "Date",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
        { title: "Status", field: "status", hidden: true },
        { title: "Submit Status", field: "feedback_submit_status", hidden: true },
        {
            title: "View",
            field: "created_by.name",
            render: (rowData) => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleShowFeedbackModal(rowData)}>
                        <Tooltip title="Click to view feedback">
                            <VisibilityIcon style={{ color: "blue" }} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    //
    //table selection
    const primeToast = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [showCommentsForm, setShoCommentsForm] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const handleShowCommentsForm = () => {
        if (validateSelectedRows()) {
            setShoCommentsForm(true);
        }
    };

    const handleCloseCommentsForm = () => {
        // setTableSelectedRows([]);
        setShoCommentsForm(false);
    };

    //=========== take action
    const [showTakeActionForm, setShoTakeActionForm] = useState(false);

    const takeActionvalidateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const handleShowTakeActionForm = () => {
        if (takeActionvalidateSelectedRows()) {
            setShoTakeActionForm(true);
        }
    };

    const handleCloseTakeActionForm = () => {
        // setTableSelectedRows([]);
        setShoTakeActionForm(false);
    };

    //=============== evaluate feedback thus approve or reject ============

    const [showEvaluteFeedbackForm, setShowEvaluteFeedbackForm] = useState(false);

    const evaluateFeedBackvalidateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const handleShowEvaluteFeedbackForm = () => {
        if (evaluateFeedBackvalidateSelectedRows()) {
            setShowEvaluteFeedbackForm(true);
        }
    };

    const handleCloseEvaluteFeedbackForm = () => {
        // setTableSelectedRows([]);
        setShowEvaluteFeedbackForm(false);
    };

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header="Summarised Feedback" style={{ marginBottom: "20px" }} toggleable>
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {activeUser?.permissions.includes("take action on summarised feedback") && tableSelectedRows.length > 0 && (
                        <Button
                            label="Take Action"
                            className="p-button-primary"
                            onClick={() => {
                                handleShowEvaluteFeedbackForm();
                            }}
                        />
                    )}
                    {showEvaluteFeedbackForm && <ApprovalForm setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showEvaluteFeedbackForm} onHide={handleCloseEvaluteFeedbackForm} onClose={handleCloseEvaluteFeedbackForm} />}

                    {/* {activeUser?.permissions.includes("create feedback") && tableSelectedRows.length > 0 && (
                        <Button
                            label="Take Action"
                            className="p-button-primary"
                            onClick={() => {
                                handleShowTakeActionForm();
                            }}
                        />
                    )}
                    {showTakeActionForm && <TakeActionForm setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showTakeActionForm} onHide={handleCloseTakeActionForm} onClose={handleCloseTakeActionForm} />}

                    {activeUser?.permissions.includes("create feedback") && tableSelectedRows.length > 0 && (
                        <Button
                            label="Comment"
                            className="p-button-primary"
                            onClick={() => {
                                handleShowCommentsForm();
                            }}
                        />
                    )}
                    {showCommentsForm && <CommentsForm setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showCommentsForm} onHide={handleCloseCommentsForm} onClose={handleCloseCommentsForm} />} */}

                    {/* {activeUser?.permissions.includes("create feedback") && <Button label="Add Feedback" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} productCategoryBrandData={productCategoryBrandData} /> */}
                </div>

                <MuiTable
                    // tableTitle="Citized Feedback"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    // showEdit={activeUser?.permissions.includes("update feedback")}
                    // handleShowEditForm={handleShowEditForm}
                    handleBulkDelete={(e, item) => handleDelete(e, item)}
                    showBulkDelete={activeUser?.permissions.includes("delete summarised feedback")}
                    loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    // //
                    // handleViewPage={(rowData) => {
                    //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //
                    exportButton={true}
                    pdfExportTitle="Summarised Citized Feedback"
                    csvExportTitle="Summarised Citized Feedback"
                    //multiple select
                    selection={true}
                    showSelectAllCheckbox={true}
                    showTextRowsSelected={true}
                    selectionChange={(selectedRows) => {
                        console.log("selected rows on sselection change : ", selectedRows);
                        setTableSelectedRows(selectedRows);
                    }}
                />

                {showFeedbackModal && <SummarisedFeedbackWithNotEditableCommentsDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
            </Panel>
        </div>
    );
}

export default ListPage;
