import React, { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";

import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { FileDownload } from "@mui/icons-material";
import { Grid, Typography, Card } from "@mui/material";

import SingleCommentsForm from "../feedback/summarised-feedback-comments/SingleCommentsForm";
import SingleCommentsEditForm from "../feedback/summarised-feedback-comments/SingleCommentsEditForm";

//
import MuiTable from "../../components/general_components/MuiTable";

import OriginalFeedbackDetailsModal from "./OriginalFeedbackDetailsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import { TabView, TabPanel } from "primereact/tabview";

//
import { getAllSummarisedFeedbackComments, getSummarisedFeedbackCommentById, postSummarisedFeedbackComment, updateSummarisedFeedbackComment, deleteSummarisedFeedbackCommentById } from "../../services/feedback/summarised-feedback-comments-service";
import { toast } from "react-toastify";
//
import useHandleQueryError from "../../hooks/useHandleQueryError";
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";

function SummarisedFeedbackWithCommentsDetailsModal({ activeUser, feedbackDetailData, ...props }) {
    console.log("🚀 ~ SummarisedFeedbackWithCommentsDetailsModal ~ feedbackDetailData fsdsdsd:", feedbackDetailData);
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a"); // Example format
    };

    // Function to display status with color
    const tagStatus = (status) => {
        let severity;
        switch (status) {
            case "active":
                severity = "success"; // green
                break;
            case "draft":
                severity = "info"; // blue
                break;
            case "rejected":
                severity = "danger"; // red
                break;
            default:
                severity = "secondary"; // grey
        }
        return <Tag severity={severity} value={status?.charAt(0)?.toUpperCase() + status?.slice(1)} />;
    };

    const getSummarisedFeedbackSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "Corporate Management":
                return "primary"; // Dark Blue or any strong color to represent high-level management
            case "AAGC":
                return "warning"; // Yellow/Orange (indicating it's under AAGC review)
            case "Accepted by AAGC":
                return "success"; // Green (indicating approval or completion by AAGC)
            case "Rejected by AAGC":
                return "danger"; // Red (indicating rejection or failure by AAGC)
            case "Directorate Officer":
                return "info"; // Blue (indicating it's being handled by a Directorate Officer)
            case "in progress":
                return "warning"; // Yellow/Orange (indicating it's still in progress)
            case "done":
                return "success"; // Green (indicating completion)
            case "failed":
                return "danger"; // Red (indicating failure)
            case "not satisfied":
                return "danger"; // Red (indicating the task is completed but with an unsatisfactory outcome)
            case "completed":
                return "success"; // Green (indicating completion)
            default:
                return "secondary"; // Gray or any other default color for unknown statuses
        }
    };

    //
    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData?.feedback);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };
    //
    const getOriginalFeedbacksSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "auditable":
                return "warning"; // Yellow/Orange
            case "non audit":
                return "danger"; // Red
            case "summarised":
                return "success"; // Green
            case "tagged":
                return "success"; // Green
            default:
                return "secondary"; // Gray or any other default color
        }
    };

    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },

        {
            title: "Feedback No",
            field: "feedback.feedback_no",
            render: (rowData) => (
                <Tag
                    value={rowData?.feedback?.feedback_no ? rowData?.feedback?.feedback_no : "N/A"}
                    severity={getOriginalFeedbacksSeverityColor(rowData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                />
            ),
        },
        { title: "Anonymous", field: "feedback.is_anonymous", render: (rowData) => (rowData?.feedback?.is_anonymous ? "Yes" : "No"), hidden: true },
        { title: "Sector", field: "feedback.sector.name", hidden: true },
        {
            title: "Message",
            field: "feedback.message",
            render: (rowData) => {
                const maxLength = 10; // Define max length before truncation
                const message = rowData?.feedback?.message || "No message";
                return message.length > maxLength
                    ? `${message.substring(0, maxLength)}...` // Truncate and add ellipses
                    : message; // Display full message if it's short enough
            },
        },
        {
            title: "Has Attachments",
            field: "feedback.has_attachments",
            render: (rowData) => {
                return rowData?.feedback?.has_attachments ? "Yes" : "No";
            },
            hidden: true,
        },

        { title: "Status", field: "feedback.status", hidden: true },
        { title: "Submit Status", field: "feedback.feedback_submit_status", hidden: true },
        { title: "Latitude", field: "feedback.latitude", hidden: true },
        { title: "Longitude", field: "feedback.longitude", hidden: true },
        // { title: "Accuracy", field: "feedback.accuracy" },
        // { title: "Altitude", field: "feedback.altitude" },
        // { title: "Speed Accuracy", field: "feedback.speed_accuracy" },
        { title: "Region", field: "feedback.region.name", hidden: true },
        { title: "District", field: "feedback.district.name", hidden: true },
        { title: "County", field: "feedback.county.name", hidden: true },
        { title: "Subcounty", field: "feedback.subcounty.name", hidden: true },
        { title: "Parish", field: "feedback.parish.name", hidden: true },
        { title: "Village", field: "feedback.village.name", hidden: true },
        { title: "Location Scope", field: "feedback.location_scope", hidden: true },
        { title: "Country", field: "feedback.country.name", hidden: true },
        { title: "Created By", field: "feedback.created_by.name", hidden: true },
        { title: "Created By Email", field: "feedback.created_by.email", hidden: true },
        { title: "Updated By", field: "feedback.updated_by.name", hidden: true },
        {
            title: "Date",
            field: "feedback.created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.feedback?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "feedback.updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.feedback?.updated_at).format("lll");
            },
        },
        {
            title: "View",
            field: "feedback.created_by.name",
            render: (rowData) => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleShowFeedbackModal(rowData)}>
                        <Tooltip title="Click to view feedback">
                            <VisibilityIcon style={{ color: "blue" }} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    //=================== comments ====================

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["summarised_feedback", "comments", "summarised_feedback_id", feedbackDetailData?.id],
        queryFn: () => getAllSummarisedFeedbackComments({ summarised_feedback_id: feedbackDetailData?.id }),
    });
    console.log("🚀 ~summarised feedback comments ListPage ~ data:", data?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => deleteSummarisedFeedbackCommentById(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["summarised_feedback", "comments"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };
    const commentColumns = [
        { title: "#", width: "5%", field: "id", hidden: true },
        {
            title: "comment",
            field: "comment",
        },
        { title: "Created By", field: "created_by.name", hidden: false },
        { title: "Created By Email", field: "created_by.email", hidden: true },
        { title: "Updated By", field: "updated_by.name", hidden: true },
        {
            title: "Date",
            field: "feedback.created_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.feedback?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "feedback.updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.feedback?.updated_at).format("lll");
            },
        },
    ];

    //
    //table selection
    const [showCommentsForm, setShoCommentsForm] = useState(false);

    const handleShowCommentsForm = () => {
        if (feedbackDetailData) {
            setShoCommentsForm(true);
        }
    };

    const handleCloseCommentsForm = () => {
        // setTableSelectedRows([]);
        setShoCommentsForm(false);
    };

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Feedback Details</div>
                </div>
            }
            visible={props.show}
            // style={{ minWidth: "60vw" }}
            onHide={props.onHide}
            maximizable
        >
            <TabView scrollable={true}>
                <TabPanel header="Feedback details">
                    {/* Feedback Information */}
                    <div className="card" style={{ marginBottom: "1rem" }} title="Feedback Information">
                        <Grid container spacing={2}>
                            {/* Column 1 */}
                            <Grid item sm={12} md={6} lg={3}>
                                <Typography variant="body1">
                                    <strong>Feedback Number:</strong>{" "}
                                    <Tag
                                        value={feedbackDetailData?.feedback_no ? feedbackDetailData?.feedback_no : "N/A"}
                                        severity={getSummarisedFeedbackSeverityColor(feedbackDetailData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                                    />
                                </Typography>

                                <Typography variant="body1">
                                    <strong>Title:</strong> {feedbackDetailData?.title || "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Sector:</strong> {feedbackDetailData?.sector?.name || "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Latitude:</strong> {feedbackDetailData?.latitude || "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Longitude:</strong> {feedbackDetailData?.longitude || "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Has Attachments:</strong> {feedbackDetailData?.has_attachments ? "Yes" : "No"}
                                </Typography>
                            </Grid>

                            {/* Location Details */}
                            {feedbackDetailData?.location_scope === "National" && (
                                <Grid item sm={12} md={6} lg={3}>
                                    <Typography variant="body1">
                                        <strong>Location Scope:</strong> {feedbackDetailData?.location_scope || "N/A"}
                                    </Typography>
                                </Grid>
                            )}

                            {feedbackDetailData?.location_scope === "International" && (
                                <Grid item sm={12} md={6} lg={3}>
                                    <Typography variant="body1">
                                        <strong>Location Scope:</strong> {feedbackDetailData?.location_scope || "N/A"}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Country:</strong> {feedbackDetailData?.country?.name || "N/A"}
                                    </Typography>
                                </Grid>
                            )}

                            {feedbackDetailData?.location_scope === "Local Government" && (
                                <Grid item sm={12} md={6} lg={3}>
                                    <Typography variant="body1">
                                        <strong>Location Scope:</strong> {feedbackDetailData?.location_scope || "N/A"}
                                    </Typography>

                                    <Typography variant="body1">
                                        <strong>Region:</strong> {feedbackDetailData?.region?.name || "N/A"}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>District:</strong> {feedbackDetailData?.district?.name || "N/A"}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>County:</strong> {feedbackDetailData?.county?.name || "N/A"}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Subcounty:</strong> {feedbackDetailData?.subcounty?.name || "N/A"}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Parish:</strong> {feedbackDetailData?.parish?.name || "N/A"}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Village:</strong> {feedbackDetailData?.village?.name || "N/A"}
                                    </Typography>
                                </Grid>
                            )}

                            {/* Column 2 */}
                            <Grid item sm={12} md={6} lg={3}>
                                {/* <Typography variant="body1">
                                    <strong>Status:</strong> {tagStatus(feedbackDetailData?.status)}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Submit Status:</strong> {tagStatus(feedbackDetailData?.feedback_submit_status)}
                                </Typography> */}

                                <Typography variant="body1">
                                    <strong>Created By:</strong> {feedbackDetailData?.created_by?.name || "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Created At:</strong> {feedbackDetailData?.created_at ? moment(feedbackDetailData.created_at).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Updated By:</strong> {feedbackDetailData?.updated_by?.name || "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Updated At:</strong> {feedbackDetailData?.updated_at ? moment(feedbackDetailData.updated_at).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
                                </Typography>
                            </Grid>

                            {/* Full Width Row for Message */}
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                    <strong>Message:</strong> {feedbackDetailData?.message || "No message provided."}
                                </Typography>
                            </Grid>

                            {/* Spatie Status */}

                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1">
                                    <strong>Current Status:</strong> <Tag value={feedbackDetailData?.spatie_current_status?.name ? feedbackDetailData?.spatie_current_status?.name : "N/A"} severity={getSummarisedFeedbackSeverityColor(feedbackDetailData?.spatie_current_status?.name)} />
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Reason:</strong> {feedbackDetailData?.spatie_current_status?.reason}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </TabPanel>

                <TabPanel header="Tagged Feedback">
                    {/* Feedback Attachments */}
                    <div className="card" title="Attachments" style={{ marginTop: "1rem" }}>
                        {/* <Typography variant="body1">
                    <strong>Attachments:</strong>
                </Typography> */}
                        <MuiTable
                            tableTitle="Orignal Tagged Feedback"
                            tableData={feedbackDetailData?.original_feedbacks ?? []}
                            tableColumns={columns}
                            // handleShowEditForm={handleShowEditForm}
                            // handleDelete={(e, item_id) => handleDelete(e, item_id)}
                            // showEdit={activeUser?.permissions.includes("update")}
                            // showDelete={activeUser?.permissions.includes("delete")}
                            // loading={isLoading || status === "loading" || deleteMutationIsLoading}
                            // //
                            // handleViewPage={(rowData) => {
                            //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                            // }}
                            // showViewPage={true}
                            // hideRowViewPage={false}
                            //
                            exportButton={true}
                            pdfExportTitle="Orignal Feedback"
                            csvExportTitle="Orignal Feedback"
                        />
                    </div>

                    {showFeedbackModal && <OriginalFeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
                </TabPanel>

                <TabPanel header="Comments">
                    {/* Feedback Comments */}
                    <div className="card" title="Attachments" style={{ marginTop: "1rem" }}>
                        {/* <Typography variant="body1">
                    <strong>Attachments:</strong>
                </Typography> */}

                        <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                            {activeUser?.permissions.includes("create summarised feedback comments") && (
                                <Button
                                    label="Comment"
                                    className="p-button-primary"
                                    onClick={() => {
                                        handleShowCommentsForm();
                                    }}
                                />
                            )}
                            {showCommentsForm && <SingleCommentsForm selectedTableRowData={feedbackDetailData} show={showCommentsForm} onHide={handleCloseCommentsForm} onClose={handleCloseCommentsForm} />}
                        </div>

                        <MuiTable
                            tableTitle="Comments"
                            tableData={data?.data?.data ?? []}
                            tableColumns={commentColumns}
                            handleShowEditForm={handleShowEditForm}
                            handleDelete={(e, item_id) => handleDelete(e, item_id)}
                            showEdit={activeUser?.permissions.includes("update summarised feedback comments")}
                            showDelete={activeUser?.permissions.includes("delete summarised feedback comments")}
                            loading={isLoading || status === "loading" || deleteMutationIsLoading}
                            //
                            //
                            hideRowEdit={(rowData) => (rowData?.created_by?.id !== activeUser?.id ? true : false)}
                            hideRowDelete={(rowData) => (rowData?.created_by?.id !== activeUser?.id ? true : false)}
                            // //
                            // handleViewPage={(rowData) => {
                            //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                            // }}
                            // showViewPage={true}
                            // hideRowViewPage={false}
                            //
                            exportButton={true}
                            pdfExportTitle="Feedback Comments"
                            csvExportTitle="Feedback Comments"
                        />

                        {selectedItem && <SingleCommentsEditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                    </div>
                </TabPanel>
            </TabView>
        </Dialog>
    );
}

export default SummarisedFeedbackWithCommentsDetailsModal;
