import React from "react";

const MuiTablePdfTemplate = ({ title = "", columns, data }) => {
    // console.log("🚀 ~ MuiTablePdfTemplate ~ columns:", columns);
    const styles = {
        container: {
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            flexDirection: "column",
            width: "100%",
        },
        logo: {
            height: "50px",
        },
        title: {
            fontSize: "18px",
            fontWeight: "bold",
        },
        paragraphContainer: {
            marginTop: "20px",
        },
        paragraph: {
            marginBottom: "10px",
            lineHeight: "1.6",
        },
        noDataMessage: {
            fontSize: "14px",
            color: "#888",
            textAlign: "center",
            marginTop: "20px",
        },
        footer: {
            marginTop: "20px",
            textAlign: "center",
            fontSize: "10px",
            color: "#666",
        },
    };

    // Helper function to dynamically access nested properties
    function getFlatPathValue(obj, path) {
        return obj[path];
    }

    return (
        <div style={styles.container}>
            {/* Header Section */}
            <div style={styles.header}>
                <img src="assets/oag_photos/uganda_oag.png" alt="Logo" style={styles.logo} />
                <span style={styles.title}>{title}</span>
            </div>

            {/* Paragraph Section or No Data Message */}
            <div style={styles.paragraphContainer}>
                {data.length > 0 ? (
                    data.map((row, rowIndex) => (
                        <p key={rowIndex} style={styles.paragraph}>
                            {columns
                                .map((col) => {
                                    const value = getFlatPathValue(row, col.field); // Safely access nested property
                                    return value ? `${col.title}: ${value}. ` : "";
                                })
                                .join(" ")}
                        </p>
                    ))
                ) : (
                    <div style={styles.noDataMessage}>No data available</div>
                )}
            </div>

            {/* Footer Section */}
            <div style={styles.footer}>Generated by OAG Citizen Feedback Platform</div>
        </div>
    );
};

export default MuiTablePdfTemplate;
