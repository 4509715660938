import axiosAPI from "../axiosApi";

export async function getAllParish(params = {}) {
    const response = await axiosAPI.get("parishes", { params: params });
    return response;
}

export async function getParishById(id) {
    const response = await axiosAPI.get(`parishes` + id);
    return response;
}

export async function postParish(data) {
    const response = await axiosAPI.post(`parishes`, data);
    return response;
}

export async function updateParish(id, data) {
    const response = await axiosAPI.put(`parishes/${id}`, data);
    return response;
}

export async function deleteParishById(id) {
    const response = await axiosAPI.delete(`parishes/${id}`);
    return response;
}
