import axiosAPI from "../axiosApi";

export async function getAllVillage(params = {}) {
    const response = await axiosAPI.get("villages", { params: params });
    return response;
}

export async function getVillageById(id) {
    const response = await axiosAPI.get(`villages/` + id);
    return response;
}

export async function postVillage(data) {
    const response = await axiosAPI.post(`villages`, data);
    return response;
}

export async function updateVillage(id, data) {
    const response = await axiosAPI.put(`villages/${id}`, data);
    return response;
}

export async function deleteVillageById(id) {
    const response = await axiosAPI.delete(`villages/${id}`);
    return response;
}
