import React from "react";
import ChoiceListPage from "./ChoiceListPage";
import ResponseListPage from "./ResponseListPage";

import BreadcrumbNav from "../../components/general_components/BreadcrumbNav";
import useAuthContext from "../../context/AuthContext";

//
function ResponsesPage({ loggedInUserData }) {
    const { getUserQuery } = useAuthContext();
    return (
        <div>
            <BreadcrumbNav />

            <ChoiceListPage loggedInUserData={getUserQuery?.data?.data} />
            <ResponseListPage loggedInUserData={getUserQuery?.data?.data} />
        </div>
    );
}

export default ResponsesPage;
