import axiosAPI from "../axiosApi";

export async function getAllNotifications(params = {}) {
    const response = await axiosAPI.get("notifications", { params: params });
    return response;
}

export async function getNotificationById(id) {
    const response = await axiosAPI.get(`notifications/` + id);
    return response;
}

export async function postNotification(data) {
    const response = await axiosAPI.post(`notifications`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateNotification(id, data) {
    const response = await axiosAPI.post(`notifications/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteNotificationById(id) {
    const response = await axiosAPI.delete(`notifications/${id}`);
    return response;
}
