import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";

const SatisfactoryRow = ({ onSubmit }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    // Form validation logic
    const validate = (values) => {
        const errors = {};
        if (!values.action) {
            errors.action = "Please select an option";
        }
        if (values.action === "satisfied" && !values.notification) {
            errors.notification = "Notification is required if satisfied";
        }
        if (values.action === "notSatisfied" && !values.reason) {
            errors.reason = "Reason is required if not satisfied";
        }
        return errors;
    };

    // Handle form submission
    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            onSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="card p-fluid">
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        {/* Action Options */}
                        <Field name="action" type="radio" value="satisfied">
                            {({ input, meta }) => (
                                <div className="field-radiobutton p-mr-4" style={{ width: "300px" }}>
                                    <RadioButton
                                        inputId="satisfied"
                                        {...input}
                                        value="satisfied"
                                        checked={input.checked}
                                        type="radio"
                                        onChange={(e) => {
                                            input.onChange(e);
                                            if (e.target.value !== "satisfied") {
                                                form.change("notification", null); // Reset notification if not satisfied
                                            }
                                        }}
                                    />
                                    <label htmlFor="satisfied" className="p-ml-2">
                                        Satisfied
                                    </label>
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>

                        <Field name="action" type="radio" value="notSatisfied">
                            {({ input, meta }) => (
                                <div className="field-radiobutton" style={{ width: "300px" }}>
                                    <RadioButton
                                        inputId="notSatisfied"
                                        {...input}
                                        value="notSatisfied"
                                        type="radio"
                                        checked={input.checked}
                                        onChange={(e) => {
                                            input.onChange(e);
                                            if (e.target.value !== "notSatisfied") {
                                                form.change("reason", null); // Reset reason if not satisfied
                                            }
                                        }}
                                    />
                                    <label htmlFor="notSatisfied" className="p-ml-2">
                                        Not Satisfied
                                    </label>
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>

                        {/* Additional Notification Field for Satisfied Option */}
                        {values.action === "satisfied" && (
                            <Field name="notification">
                                {({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="notification">Notification</label>
                                        <InputTextarea id="notification" {...input} rows={3} cols={30} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                        )}

                        {/* Additional Reason Field for Not Satisfied Option */}
                        {values.action === "notSatisfied" && (
                            <Field name="reason">
                                {({ input, meta }) => (
                                    <div className="field">
                                        <label htmlFor="reason">Reason</label>
                                        <InputTextarea id="reason" {...input} rows={3} cols={30} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                        )}

                        <Button type="submit" label="Submit" className="p-button-primary" />
                    </form>
                )}
            />

            {/* Confirmation Dialog */}
            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                style={{ width: "350px" }}
                modal
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus />
                        <Button label="No" icon="pi pi-times" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                <p>Are you sure you want to submit this action?</p>
            </Dialog>
        </div>
    );
};

export default SatisfactoryRow;
