import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";

import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllResponses, deleteResponseById } from "../../services/responses/responses-service";
import { getAllChoices } from "../../services/choices/choices-service";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../components/general_components/MuiTable";
import { Card } from "primereact/card";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Panel } from "primereact/panel";
import { useLocation } from "react-router-dom";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import fullscreen from "highcharts/modules/full-screen";
import noData from "highcharts/modules/no-data-to-display";

//
import useHandleQueryError from "../../hooks/useHandleQueryError";
import useHandleMutationError from "../../hooks/useHandleMutationError.js";
import { SubTitle } from "chart.js";

// Initialize the modules
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);
noData(Highcharts);

function getResponsePercentage(responses, choices) {
    // Initialize an object to store the count of each response
    let responseCounts = {};

    // Extract options from the choices and initialize counts for all choices
    let choiceOptions = choices.map((choice) => choice.option);
    choiceOptions.forEach((option) => {
        responseCounts[option] = 0;
    });

    // Count the occurrences of each response
    responses.forEach((response) => {
        if (responseCounts.hasOwnProperty(response.response)) {
            responseCounts[response.response]++;
        }
    });

    // Calculate the total number of responses
    const totalResponses = responses.length;

    // Prepare the result array with percentage calculations
    let result = choiceOptions.map((option) => {
        return {
            name: option,
            y: totalResponses > 0 ? (responseCounts[option] / totalResponses) * 100 : 0,
            number: responseCounts[option],
        };
    });

    let result2 = choiceOptions.map((option) => {
        return {
            name: option,
            y: responseCounts[option],
        };
    });

    return result;
}

function ResponseListPage({ loggedInUserData, productCategoryBrandData, ...props }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedQuestion } = location.state || {};

    // const query2Client = useQueryClient();
    // const { data2, isLoading2, isError2, error2, status2 } = useQuery({
    //     queryKey: ["allChoicesForQuestion", selectedQuestion.id],
    //     queryFn: () => getAllChoices({ question_id: selectedQuestion.id }),
    // });
    // console.log("🚀 ~ choices Responses ListPage ~ data:", data2?.data?.data);

    // // Use the custom hook to handle errors with useMemo on the error object
    // useHandleQueryError(isError2, error2);
    const queryClient2 = useQueryClient();

    // Access cached data for a query using its queryKey
    // const cachedChoices = queryClient2.getQueryData(["choices", selectedQuestion.id]);

    // Query for choices
    const {
        data: choicesData,
        isLoading: isLoadingChoices,
        isError: isErrorChoices,
        error: errorChoices,
    } = useQuery({
        queryKey: ["choices2", selectedQuestion.id],
        queryFn: () => getAllChoices({ question_id: selectedQuestion.id }),
    });

    console.log("🚀 ~ Responses ListPage ~ data Choices:", choicesData?.data?.data);

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["responses", selectedQuestion.id],
        queryFn: () => getAllResponses({ question_id: selectedQuestion.id }),
    });
    console.log("🚀 ~ Responses ListPage ~ data:", data?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => deleteResponseById(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["responses"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    let tableId = 0;
    const columns = [
        {
            title: "ID",
            width: "5%",
            field: "id",
        },
        {
            title: "Question",
            field: "question",
        },
        {
            title: "Response Type",
            field: "response_type",
            render: (rowData) => {
                if (rowData.response_type === "single") {
                    return "Single Choice";
                }
                if (rowData.response_type === "multi") {
                    return "Multiple Choice";
                }
                if (rowData.response_type === "open") {
                    return "Open Ended";
                }
            },
        },
        {
            title: "Date",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
    ];

    //highcharts

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
        },
        title: {
            text: selectedQuestion.question,
            // align: "left",
        },
        subtitle: {
            text: `${(data?.data?.data ?? []).length} ${(data?.data?.data ?? []).length === 1 ? "response" : "responses"}`,
        },
        tooltip: {
            pointFormat: "<b>Frequency: </b>{point.number:.0f}<br><b>Percentage: </b>{point.percentage:.0f}%",
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },
        series: [
            {
                name: "Responses",
                colorByPoint: true,
                data: getResponsePercentage(data?.data?.data ?? [], choicesData?.data?.data ?? []),

                // [
                //     {
                //         name: "because I am",
                //         y: 74.77,
                //     },
                //     {
                //         name: "because I want",
                //         y: 25.23,
                //     },
                // ],
            },
        ],
    };

    // const [responses, setResponses] = useState([
    //     { id: 1, option: "A self-Powering system" },
    //     { id: 2, option: "Unemployment" },
    //     { id: 3, option: "Solar Power Bank" },
    //     { id: 4, option: "Blind man eyes" },
    //     { id: 5, option: "Online shops" },
    //     { id: 6, option: "Moving architecture" },
    //     { id: 7, option: "WatchMe_Bed" },
    //     { id: 8, option: "Fuel Quench" },
    //     { id: 9, option: "Emotion Reader" },
    // ]);

    const listTemplate = (response) => {
        return (
            <div style={{ padding: "20px", margin: "5px", borderBottom: "1px solid #eee", width: "100%", backgroundColor: "#F1F2F3", borderRadius: "10px" }}>
                {response.response}
                {/* <Button
                    icon="pi pi-pencil"
                    label="Edit"
                    style={{ height: "20px", backgroundColor: "transparent", color: "green", fontSize: "12px", border: "none" }} // Adjust the size as needed
                    onClick={() => setShowAddForm(true)}
                />
                <Button
                    icon="pi pi-pencil"
                    label="Edit"
                    style={{ height: "20px", backgroundColor: "transparent", color: "green", fontSize: "12px", border: "none" }} // Adjust the size as needed
                    onClick={() => setShowAddForm(true)}
                /> */}
            </div>
        );
    };

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header="Responses" style={{ marginBottom: "20px" }} toggleable>
                {/* <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {activeUser?.permissions.includes("create") && <Button label="Add Response" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} selectedPollId={selectedQuestion.id} productCategoryBrandData={productCategoryBrandData} />
                </div> */}
                {!isLoading && !isLoadingChoices && selectedQuestion.response_type !== "open" && (data?.data?.data ?? []).length > 0 && <HighchartsReact highcharts={Highcharts} options={options} />}
                {/* <h3>Response List</h3> */}
                {!isLoading && !isLoadingChoices && selectedQuestion.response_type === "open" && (data?.data?.data ?? []).length > 0 && (
                    <div>
                        <h5 style={{ padding: "10px", margin: "5px", width: "100%", borderRadius: "10px" }}>
                            {(data?.data?.data ?? []).length} {(data?.data?.data ?? []).length === 1 ? "response" : "responses"}
                        </h5>
                        <DataView value={data?.data?.data} layout="list" itemTemplate={listTemplate} paginator rows={7} />
                    </div>
                )}

                {!isLoading && !isLoadingChoices && (data?.data?.data ?? []).length <= 0 && <div style={{ padding: "20px", margin: "5px", borderBottom: "1px solid #eee", width: "100%" }}>No Responses yet</div>}
                {(isLoading || isLoadingChoices) && <div style={{ padding: "20px", margin: "5px", borderBottom: "1px solid #eee", width: "100%" }}>Loading...</div>}

                {/* </div> */}
                {/* <MuiTable
                    tableTitle="Responses"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={activeUser?.permissions.includes("update")}
                    showDelete={activeUser?.permissions.includes("delete")}
                    loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    // //
                    // handleViewPage={(rowData) => {
                    //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //
                    exportButton={true}
                    pdfExportTitle="Responses"
                    csvExportTitle="Responses"
                /> */}
                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
            </Panel>
        </div>
    );
}

export default ResponseListPage;
